import React, { Component } from 'react';
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { computed,  } from 'mobx'
import { NavLink } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/outline'
import { IconDashboard,  } from './Icons'
import { useLocation } from 'react-router-dom'
import Body from './Components/Body'
import { withRouter } from "react-router-dom";
import config from './config';

function HeaderExpand(props) {
	const location = useLocation();
	return <SuperHeader 
			active={location.pathname !== "/quiz" ? true : false}
			
			>{props.children}</SuperHeader>
}

@inject('store')
@observer
class SidebarCompontent extends Component {

	state = {
		onTrial: true,
		isOpenMobileMenu: false
	}

	helpModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: '200px'
        }
    }

	constructor(props) {
		super(props);
		if(this.props.location.pathname === "/signup"){
			this.props.history.push('/')
		}
		if(this.props.location.pathname === "/login"){
			this.props.history.push('/')
		}
		this.setWrapperRef = this.setWrapperRef.bind(this);
    	this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount(){
		this.setState({onTrial: this.props.store.profile.onTrial});
		document.addEventListener('keydown',this.shortcutHandler);
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown',this.shortcutHandler);
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	  /**
   * Set the wrapper ref
   */
	  setWrapperRef(node) {
		this.wrapperRef = node;
	  }
	
	  /**
	   * Alert if clicked on outside of element
	   */
	  handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpenMobileMenu) {
			this.setState({isOpenMobileMenu: false});
		}
	  }

	switchToProfessional = () => {
		if(this.props.location.pathname !== "/quiz"){
			this.props.store.switchAccountModes('professional');
			this.setState({onTrial: false});
		} else {
			this.props.store.setWarningModal(true);	
		}
	}

	switchToTrial = () => {
		if(this.props.location.pathname !== "/quiz"){
			this.props.store.switchAccountModes('trial');
			this.setState({onTrial: true});
		} else {
			this.props.store.setWarningModal(true);	
		}
	}

	helpModal = (show = true) => {
		this.props.store.setHelpModal(show);
		this.setState({isOpenMobileMenu: false});
	}


	shortcutHandler = e => {
		if(e.keyCode===75 && e.ctrlKey){
			e.preventDefault();
			// select all text in input with id q
			document.getElementById("q").focus();
			document.getElementById("q").select();
		}
	}

	onKeyUp = (e) => {
		if(this.props.location.pathname !== "/search"){
			this.props.history.push('/search')
		}
		if(e.keyCode === 8){
			if(this.props.store.toolsKeyword === ""){
				this.props.history.push('/')
			}
		}
	}

	toggleMobileMenu = () => {
		const menuState = !this.state.isOpenMobileMenu;
		this.setState({isOpenMobileMenu: menuState});
	}

	buyMore = () => {
		window.location.href = `${config.landingPageUrl}/shop/`;
	}

	landingPage = () => {
		window.location.href = config.landingPageUrl;
	}

	@computed get fromColor(){
		if(this.props.store.profile.credits <= 0){
			return "bg-red-200 text-red-600"
		}
		if(this.props.store.profile.status === "trialing"){
			return ""
		}
		if(this.props.store.profile.status === "active"){
			return ""
		}
		if(this.props.store.profile.status === "incomplete"){
			return ""
		}
		return "bg-red-200 text-red-600"
	}



	render() {
		return (
						<>
							 		<HeaderExpand>
										<Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
											<div className="mr-4">
												<a className='link-cursor' onClick={this.landingPage}><Logo /></a>
											</div>
											
										</Body>
								 </HeaderExpand>
								{/* Desktop menu */}
								<div className="border-b border-gray-300 bg-white shadow-sm desktop-header">
										<div className="container flex mx-auto px-4 md:px-28 flex select-none">
														<NavLink to="/"
														 exact
														 tabIndex={-1}
														 onClick={()=>this.props.store.toolsKeyword = ""}
														 activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
														 className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center">
															 <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
															 <div className="hidden lg:block">Dashboard</div>
															</NavLink>
														 
														<div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
														</div>
															{this.state.onTrial ? (
															<div
															
																className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} rounded-t-md font-medium transition items-center`}>
																	<div className={`pt-4`}>
																		Trial Tokens: {this.props.store.profile.trialGameTokens} <br/>
																		Game Tokens: {(this.props.store.profile.gameTokens ? this.props.store.profile.gameTokens: 0)}&nbsp;&nbsp;
																	</div>
																<button 
																onClick={this.switchToProfessional}
																className={
																	`select-none py-3 px-12 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
			
																>Switch to Game Mode</button>
															</div> ) : ( <div
															className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} rounded-t-md font-medium transition items-center`}>
																	<div className={`pt-4`}>
																		Trial Tokens: {this.props.store.profile.trialGameTokens} <br/>
																		Game Tokens: {(this.props.store.profile.gameTokens ? this.props.store.profile.gameTokens: 0)}&nbsp;&nbsp;
																</div>
															<button 
																onClick={this.switchToTrial}
																className={
																	`select-none py-3 px-12 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
			
																>Switch to Trial Mode</button> </div> ) }
															<button className="help-icon" title="What is Trial/Game mode?" onClick={this.helpModal}>?</button> 
														<div className=" my-profile-link-top">
														<NavLink to="/my-profile"
														 exact
														 tabIndex="-1"

														 className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-200 rounded-t-md font-medium transition items-center`}> 
														 { this.props.store.profile.profile_picture? <img src={this.props.store.profile.profile_picture} className="w-7 h-7 lg:mr-4 transition" /> : <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />}
															<div className="hidden lg:block"> My Profile <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"className="w-6 h-6 inline-block">
																  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
															</svg>
															</div>
															</NavLink>
															<div className="dropdown-content-profile">
																<a onClick={this.buyMore}>Buy more Game Tokens</a>
																<NavLink to="/my-profile/update" exact>Edit Profile</NavLink>
																<NavLink to="/my-profile/withdraw-tokens" exact>Withdraw Tokens</NavLink>
																<NavLink to="/my-profile/transaction-history" exact>Transactions History</NavLink>
																<NavLink to="/my-profile/referrals" exact>Referrals</NavLink>
																<NavLink to="/my-profile/help" exact>Help</NavLink>
																<a onClick={this.props.store.handleLogout}>Logout</a>
															</div>
														</div>
														
														
												</div>
												
								</div>
								{/* Desktop menu */}
								{/* Mobile menu */}
								<div id="hamburger-icon" ref={this.setWrapperRef} className={`mobile-header ${this.state.isOpenMobileMenu ? 'open-submenu': ''}`} >
									<section onClick={ (e) => this.toggleMobileMenu()}>
									<div className="bar1"></div>
									<div className="bar2"></div>
									<div className="bar3"></div>
									</section>
									<ul className="mobile-menu">
									<li><NavLink to="/" onClick={ (e) => this.toggleMobileMenu()} exact>Dashboard</NavLink></li>
									<li id="login"><a onClick={this.buyMore}>Buy more Game Tokens</a></li>
									<li><NavLink to="/my-profile/update" onClick={ (e) => this.toggleMobileMenu()} exact>Edit Profile</NavLink></li>
									<li>
										{this.state.onTrial ? (<a onClick={(e) => { this.switchToProfessional(); this.toggleMobileMenu(); }}>Switch to Game Mode</a> ) : (<a onClick={(e) => { this.switchToTrial(); this.toggleMobileMenu(); }}>Switch to Trial Mode</a>) }
									</li>
									<li><NavLink to="/my-profile/withdraw-tokens" onClick={ (e) => this.toggleMobileMenu()} exact>Withdraw Tokens</NavLink></li>
									<li><NavLink to="/my-profile/transaction-history" onClick={ (e) => this.toggleMobileMenu()} exact>Transactions History</NavLink></li>
									<li><NavLink to="/my-profile/referrals" onClick={ (e) => this.toggleMobileMenu()} exact>Referrals</NavLink></li>
									<li><NavLink to="/my-profile/help" onClick={ (e) => this.toggleMobileMenu()} exact>Help</NavLink></li>
									<li id="signup"><a onClick={this.props.store.handleLogout}>Logout</a></li>
									<li>Trial Tokens: {this.props.store.profile.trialGameTokens} <br/> Game Tokens: {this.props.store.profile.gameTokens } </li>
									</ul>
								</div>
								{/* Mobile menu */}

								{this.props.children}
						
						</>
			)
		}
}	

const Logo = () => (
	<img src="/logo_with_text.svg" className="w-60"/>	
)

const Input = styled.input`
	
`

const Textarea = styled.textarea`
	position: fixed;
	right:-9990px;
	top:-9990px;
`

// transition:ease-in-out 0.5s margin-top;
// 	transition-delay:0.05s;

const SuperHeader = styled.div`
	height:150px;
	background:white;
	margin-top:${props => props.active ? "0px" : "-150px" };
	display:${props => props.hidden ? "hidden" : "flex" };
	background-image:url(${require('./pattern-dots.svg').default});
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
	
`


	
export default withRouter(SidebarCompontent)