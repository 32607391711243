import React, { Component } from 'react';
import {
    PencilIcon,
    ClockIcon,
  } from '@heroicons/react/outline'

import  { Grid, Col } from '../Components/Body'
import EntryText  from '../Components/EntryText'
import Button from '../Components/Button'
import Output from '../Components/Output'
import Logo from '../Logo'
import Countdown from 'react-countdown';

import { observable, makeObservable } from 'mobx'
import { observer, inject,  } from 'mobx-react'
import config from './../config';


@inject('store')
@observer
class EditProfile extends Component {

	countdown
	@observable output = ""
	@observable outputs = []
    @observable outputError = ""

	@observable option = "Start Using"
	@observable options= []
	@observable currentOption = ""

    @observable loading = false
	@observable date = Date.now() + 1000

	// Basic Input
	@observable prompt = ""
	@observable promptError = ""
    @observable promptNumber = 0

	// Options of Inputs
	@observable promptOptions = []

	// Currently Selected Input Option
	@observable currentPromptOption = ""

	// Multiple Input Option
	@observable prompts = []

	@observable feedbacks = []
    
    @observable tool = {}

	state = {
		isEditingDisabled: false,
		success: false,
		fName: this.props.store.profile.fname,
		lName: this.props.store.profile.lname,
		password: '',
		confirmPassword: '',
		error_password: false,
		profile_picture: this.props.store.profile.profile_picture,
		uploadImg: null,
		errorMessage: null
	}
    
    constructor(props) {
        super(props)
        makeObservable(this)
    }

	componentDidMount() {

	}


	onClick = (e) => {
		e.preventDefault();

		if (this.state.password !== this.state.confirmPassword && this.state.password != '') {
			this.setState({ error_password: true});
			return;
		}
		
		let bodyFormData = new FormData();
		bodyFormData.append('fName', this.state.fName); 
		bodyFormData.append('lName', this.state.lName); 
		bodyFormData.append('password', this.state.password); 
		bodyFormData.append('confirm_password', this.state.confirmPassword); 
		bodyFormData.append('image', this.state.uploadImg); 

		this.setState({isEditingDisabled: true, error_password: false, errorMessage: ''});
		this.props.store.updateProfile(bodyFormData).then((result) => {
			console.log('result', result)

			this.setState({isEditingDisabled: false, success: true});
		}, (error) => {
			console.log('error', error)
			this.setState({isEditingDisabled: false, success: false, errorMessage: error.response.data.error});
		})
	}

	onChange = (value, key) => {
		this.setState({[key]: value});
	}
	
	updateFile = (e) => {
		this.setState({uploadImg: e.target.files[0]});
	}
	render() {
		return (
				<>
					<Grid>
						<Col span="10">
	
							<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}>
								<div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="flex items-center">

									<div className=" mt-0 ml-0 text-left">
										<div as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Edit Profile
										</div>
										</div>
									</div>
									
										{this.state.success ? <div className="text-green-600 bg-green-100 rounded-md p-2 text-center mt-4">
											Profile updated successfully!
											</div> : null}

											{this.state.error_password ?<div className="text-red-600 bg-red-100 rounded-md p-2 text-center mt-4">
												Password & confirm password do not match.
											</div>: null}

											{this.errorMessage ?<div className="text-red-600 bg-red-100 rounded-md p-2 text-center mt-4">
											{this.errorMessage}
											</div>: null}
					
									<form>
									<div className="md:flex">
										<div className="flex flex-col min-w-0 md:pr-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">First Name</label>
											<input defaultValue={this.props.store.profile.fname} onChange={e=>this.onChange(e.target.value,'fName')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" />
										</div>
										<div className="flex flex-col min-w-0 md:pl-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Last Name</label>
											<input defaultValue={this.props.store.profile.lname} onChange={e=>this.onChange(e.target.value,'lName')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto"  />
										</div>
									</div>
									<div className="md:flex">
										<div className="flex flex-col min-w-0 md:pr-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email</label>
											<input value={this.props.store.profile.email} disabled={true} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" />
										</div>
										<div className="flex flex-col min-w-0 md:pr-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Profile Picture (Only png, jpg allowed)</label>
											<input type="file" onChange={e=>this.updateFile(e)} id="myfile" name="myfile" />

										</div>
										
									</div>

									<div className="md:flex">
										<div className="flex flex-col min-w-0 md:pr-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">New Password</label>
											<input  placeholder="Leave blank if don't want to update" onChange={e=>this.onChange(e.target.value,'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" />
										</div>
										<div className="flex flex-col min-w-0 md:pl-2 flex-1">
											<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Confirm Password</label>
											<input  placeholder="Leave blank if don't want to update" onChange={e=>this.onChange(e.target.value,'confirmPassword')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" />
										</div>
									</div>

					
									<Button disabled={this.state.isEditingDisabled} onClick={(e) => this.onClick(e)}>Update</Button>
									</form>
						
								</div>

               				 </div>
				
						</Col>
						
				</Grid>
			</>
		)
	}
}

  


export default EditProfile