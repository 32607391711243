const dev = {
	baseURL: "http://localhost:3080/api/",
	landingPageUrl: "https://www.ovalpixel.com",
	gameAppUrl: "https://aigame.ovalpixel.com",
	stripe: {
		free: "price_1JcQD6HIZYvvnKladKayEvOZ",
		entry: "price_1JL68HHIZYvvnKlaBJWS5uDe",
		pro: "price_1JLQhlHIZYvvnKlakrqF8khB"
	},
	questionsPerTopic: 100,
	trialGameTokens: 25,
	cryptoTokenValue: 0.02301,
	recaptchaSitekey: "6LfnBoolAAAAADDfXGvQP-bh2z-OGeeBs1JDiac7",
	referralToken: 10,
	bananaCrystalSSO: 'https://dev-app.local/?auth=sso'
};
  
const prod = {
	baseURL: 'https://backend.ovalpixel.com/api/',
	landingPageUrl: "https://www.ovalpixel.com",
	gameAppUrl: "https://aigame.ovalpixel.com",
	stripe: {
		free: "price_1JcQsUHIZYvvnKlaEPy958NF",
		entry: "price_1JLpWpHIZYvvnKlaHxiCr7Js",
		pro: "price_1JLpWxHIZYvvnKlamATZ7Awm"
	},
	questionsPerTopic: 100,
	trialGameTokens: 25,
	cryptoTokenValue: 0.01,
	recaptchaSitekey: "6LfnBoolAAAAADDfXGvQP-bh2z-OGeeBs1JDiac7",
	referralToken: 10,
	bananaCrystalSSO: 'https://www.ovalpixel.com/?auth=sso'
};
  
const config = process.env.NODE_ENV === 'development'
	? dev
	: prod;
  
export default config;