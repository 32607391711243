import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { DatabaseIcon, LinkIcon, ArrowDownIcon, UsersIcon } from '@heroicons/react/outline'
import TwitterIcon from '../media/twitter'
import FacebookIcon from '../media/facebook'
import config from './../config';
import  { Grid, Col } from '../Components/Body'
import moment from 'moment';

@inject('store')
@observer
class Referral extends Component {

	value = `${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}`
	htmlCode = `<a href="${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}"><img width="497" src="${config.gameAppUrl}/OvalPixel-ref1.jpg"></a>`;
	state = {
		currentTab: 'referral_code',
		recentReferrals: [],
		totalEarnedTokens: 0
	}

	componentDidMount() {
		this.props.store.getRecentReferral().then((referrals) => {
			let totalTokens = (referrals.length * config.referralToken);
			this.setState({recentReferrals: referrals, totalEarnedTokens: totalTokens});
		})
	}

	switchTab = (tabName) => {
		if (tabName) {
			this.setState({currentTab: tabName});

		}
	}

	render() {
		return (
			<>

<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

				 <h2 className="text-4xl">Referral Program</h2>
				 <h2 className="text-gray-500 mb-4">Refer friends and family and earn <strong>{config.referralToken} game tokens</strong> per referral!</h2>
				 <div className="mb-4">
				 	 <p><a href="https://ovalpixel.com/referral-program-refer-friends/" className="text-sm text-green-500" target="_blank">About Friends Referral Program</a></p>
					  <p><a href="https://ovalpixel.com/how-to-earn-referral-rewards-using-your-referral-code/" className="text-sm text-green-500" target="_blank">How To Earn Rewards Using your Referral Code</a></p>
				 </div>

				 <div class="tab-buttons">
					 <button onClick={(e) => {this.switchTab('referral_code')}} className={this.state.currentTab === 'referral_code' ? 'active': null}>Referral Code and Links</button>
					 <button onClick={(e) => {this.switchTab('recent_referrals')}} className={this.state.currentTab === 'recent_referrals' ? 'active': null}>Recent Referrals</button>
				</div>
				
				{/* Referal link tab */}
				{this.state.currentTab === 'referral_code' ?
				(<div className="referral_code">
				 <div className="flex items-center mb-4">
					<div className={`text-lg leading-6 font-medium text-gray-900`}>
						Referral Code: <strong>{this.props.store.profile.referralId}</strong>
					</div>
					<br/>
                  </div>

			 	 <div className="flex items-center">
					<div className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10`}>
						<DatabaseIcon className={`h-3 w-3 md:h-6 md:w-6 text-yellow-700 `} aria-hidden="true" />
					</div>
              	  <div className=" mt-0 ml-4 text-left">
                    <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    	Earn 10 game tokens!
                    </div>
                      <p className="text-sm text-gray-500">
                        When people sign up using your referral link below, you'll earn 10 game tokens.
                      </p>
                    </div>
                  </div>

				  <div className="flex items-center">
					<div className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 sm:mx-0 sm:h-10 sm:w-10`}>
						<ArrowDownIcon className={`h-3 w-3 md:h-6 md:w-6 text-gray-400 `} aria-hidden="true" />
					</div>
                  </div>
				  
                <div className="flex items-center">
					<div className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10`}>
						<UsersIcon className={`h-3 w-3 md:h-6 md:w-6 text-green-700 `} aria-hidden="true" />
					</div>
              	  <div className=" mt-0 ml-4 text-left">
                    <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    	Share your referral link
                    </div>
                      <p className="text-sm text-gray-500">
                        Use the link which contains your referral code to share on social media and earn game credits!
                      </p>
                    </div>
                  </div>

				  <div className="flex mt-4 mb-4">
				 		<div className="mt-1 flex rounded-md flex-1">
                       
                        <input
                          id="referral"
                          type="text"
						  value={this.value}
                          className="focus:ring-indigo-500 border focus:border-indigo-500 flex-1 rounded border-gray-300 py-3 px-6 flex-1 bg-gray-100 hover:bg-gray-200"
                          placeholder="www.example.com"
						  onChange={()=>{}}
						  onClick={()=>{
							document.getElementById("referral").select();  
							  this.props.store.copyToClipboard(`${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}`
							)}}
                        />
                      </div>

				  </div>

				 <div className="grid grid-cols-12">
					<div className="flex items-center" onClick={()=>this.props.store.copyToClipboard(`${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}`)}>
							<div className={`cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full  sm:mx-0 sm:h-12 sm:w-12`}>
								<LinkIcon className={`h-3 w-3 md:h-8 md:w-8  `} aria-hidden="true" />
							</div>
					</div>

					{/* // eslint-disable-next-line react/jsx-no-target-blank */}
					<a rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?text=Check out OvalPixel's Play to Earn Quiz Game...You can Earn Rewards by simply playing the game: ${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}`} className="flex items-center" target="_blank">
							<div className={`cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full  sm:mx-0 sm:h-12 sm:w-12`}>
								<TwitterIcon className={`h-3 w-3 md:h-8 md:w-8`} aria-hidden="true" />
							</div>
					</a>

					<a rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=${config.gameAppUrl}/signup?referral=${this.props.store.profile.referralId}`} className="flex items-center" target="_blank">
							<div className={`cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full  sm:mx-0 sm:h-12 sm:w-12`}>
								<FacebookIcon className={`h-3 w-3 md:h-8 md:w-8`} aria-hidden="true" />
							</div>
					</a>
				</div>
				
				<div className="flex mt-4 mb-4">
				 		<div className="mt-1 flex rounded-md flex-1">
                       
                        <input
                          id="referralHtml"
                          type="text"
						  value={this.htmlCode}
                          className="focus:ring-indigo-500 border focus:border-indigo-500 flex-1 rounded border-gray-300 py-3 px-6 flex-1 bg-gray-100 hover:bg-gray-200"
                          placeholder="www.example.com"
						  onChange={()=>{}}
						  onClick={()=>{
							document.getElementById("referralHtml").select();  
							  this.props.store.copyToClipboard(this.htmlCode)}}
                        />
                      </div>
				</div>
				<div className="flex mt-4 mb-4">
					<a href={config.gameAppUrl+'/signup?referral='+this.props.store.profile.referralId}><img width="497" src="/OvalPixel-ref1.jpg" /></a>
				</div>

				</div> ):null}
				{/* Referal link tab */}

				{/* Recent Referal tab */}
				{this.state.currentTab === 'recent_referrals' ?
				(<div className="recent_referrals">
					<Grid>
						<Col span="10">
	
							{/* <div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}> */}
								<div className="px-4 pb-4 sm:p-6 sm:pb-4">
					
									<Grid>
										<Col span="6" className=" mt-0 ml-4 text-left">
											<div as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Recent Referrals
											</div>
										</Col>
										<Col span="6" className="text-right">
											Total Referred: <strong>{this.state.recentReferrals.length}</strong><br />
											Total Earned Tokens: <strong>{this.state.totalEarnedTokens}</strong>
										</Col>
									</Grid>

									<br/>
									<div className="transaction-list">

										<table className="table-auto border-collapse border-solid text-center border-dark-green-500 border	 w-full	">
											<thead>
												<tr className="border border-solid leading-10">
													<th>S. No</th>
													<th>SignUp Date</th>
													<th>Referee*</th>
													<th>Rewards Earned</th>
												</tr>
											</thead>
											<tbody  className="border border-solid leading-10">
												{this.state.recentReferrals.length > 0 ? 
												(
													this.state.recentReferrals.map((user, index) => 
														<tr>
															<td>{index+1}</td>
															<td>{moment(user.created).format('MM/DD/YYYY')}</td>
															<td>{user.fname} {user.lname}</td>
															<td>{config.referralToken}</td>
														</tr>
													)
												):	
												(<tr className="p-2" >
													<td colSpan="5">Currently there are no referrals! <br/>Invite friends to join OvalPixel using your referral code or links.</td>
												</tr>)}
											</tbody>
										</table>	
									</div>
								</div>
{/* 
               				 </div> */}
				
						</Col>
						
				</Grid>
				</div>):null}
				{/* Recent Referal tab */}

			</div>

        </div>
			
			</>
		)
	}
}

export default Referral