import axios from 'axios'
import { observable, computed, makeObservable } from 'mobx'
import { configure } from "mobx"
import FuzzySet from 'fuzzyset'
import Filter from 'bad-words'

import TOOLS from './tools'
import config from './config'


let filterBadWords = new Filter()

let baseURL = config.baseURL

configure({  enforceActions: "never", })

let api = axios.create({ baseURL, });

const FuzzySearch = FuzzySet([...TOOLS.map(tool => tool.title)]);

class appStore {

	api = api
	@observable baseURL = baseURL
	@observable redirect = ``
	@observable editor
	@observable editorIsLoading = true
	@observable verified = null
	@observable resetToken = null

	
	// User Profile
	@observable profile = {}
	@observable isLoggedIn = false
	@observable loginLoading = false
	@observable categories = []
	@observable landingPageUrl = config.landingPageUrl
	@observable quizQuestions = []
	@observable helpModalOpen = false
	@observable tmdbAPIkey = config.tmdbApiKey
	@observable warningOpen = false
	@observable apiErrorModalOpen = false
	warningOpen
	referral = ''
	editor

	constructor(){
		makeObservable(this);
		this.init()
		this.verified = new URLSearchParams(window.location.search).get("verified")
		this.resetToken = new URLSearchParams(window.location.search).get("reset_token")

		// Check credits every time, and log out people who aren't authenticated
		this.api.interceptors.response.use((response) => {
			this.updateCredits(response)
			return response;
		}, (error) => {
			console.log(error)
			console.log(`error.response.statusText`,error.response.statusText)
			if (error.response && error.response.statusText === "Token Authentication Failed") {
				this.handleLogout()
			}
			if (error.response && error.response.statusText === "No Credit Remaining") {
				this.noCreditsRemainPrompt()
			}
			return Promise.reject(error);
		});
		
	}

	noCreditsRemainPrompt = () => {
		// set the browser url to the no-credits page
		window.location.pathname = "/my-profile"
	}

	init = async () => {
		try {
			this.referralTrackingCode()
			const profile = localStorage.getItem("profile")
			const token = localStorage.getItem("token")
			if (profile && token) {
				this.api.defaults.headers.common['x-access-token'] = token;
				this.profile = JSON.parse(profile)
				this.isLoggedIn = true
				this.refreshTokenAndProfile();
			}

			setInterval(this.getTokens, (60000*2));
		} catch (err){
			console.log(err)
		}
	}

	@observable referral = ""

	referralTrackingCode = async () => {
		let referral = new URLSearchParams(window.location.search).get("referral")
		if(referral){
			this.setReferral(referral)
		} else {
			this.initReferral()
		}
	}

	setReferral = async (referral) => {
		this.referral = referral
		localStorage.setItem("referral", JSON.stringify(referral))
	}
	
	initReferral = async () => {
		const referral = localStorage.getItem("referral")
		this.referral = referral
	}

	
	loginWithDataTokenAndProfile = async (data) => {
		this.setToken(data.token)
		data.profile.profile_picture = data.profile.profile_picture ? baseURL+'/uploads/'+data.profile.profile_picture : null;
		this.setProfile(data.profile)
		this.isLoggedIn = true
	}

	refreshTokenAndProfile = async () => {
		try {
			let data = await this.api
				.post('/user/refresh/profile')
				.then(({ data }) => data)
			if(data){
				data.profile.profile_picture = data.profile.profile_picture ? baseURL+'/uploads/'+data.profile.profile_picture : null;
				this.setProfile(data.profile)
			}
		} catch (err) {
			console.log(err)
			this.handleLogout()
		}
	}

	switchAccountModes = async (mode) => {
		try {
			let data = await this.api
				.post('/user/switch/mode', {account_mode: mode})
				.then(({ data }) => data);
			await this.refreshTokenAndProfile();
		} catch (err) {
			console.log(err)
		}
	}

	
	getCategories = async () => {
		try {
			let data = await this.api
				.get('/quiz/categories')
				.then(({ data }) => data)
			if(data){
				this.setCategories(data)
			}
		} catch (err) {
			console.log(err)
			this.handleLogout()
		}
	}

	setCategories = async (categories) => {
		this.categories = categories
	}

	setToken = async (token) => {
		this.api.defaults.headers.common['x-access-token'] = token;
		localStorage.setItem("token", token)
	}

	setProfile = async (profile) => {
		this.profile = profile
		localStorage.setItem("profile", JSON.stringify(profile))
	}

	handleLogout = () => {
		this.isLoggedIn = false
		this.profile = {}
		this.api.defaults.headers.common['x-access-token'] = ""
		localStorage.removeItem('token')
		localStorage.removeItem('profile')
	}

	@observable toolsKeyword = ""
	onChangeToolsKeyword = (e) => {
		this.toolsKeyword = e.target.value
	}
	@computed get tools() {
		// let tools = TOOLS.filter(tool => tool.title.toLowerCase().includes(this.toolsKeyword.toLowerCase()))
		let fuzzyTools = FuzzySearch.get(this.toolsKeyword, 0.5)
		if(fuzzyTools && fuzzyTools.length){
			let fuzzySummary = fuzzyTools.map(fuzzyTool => fuzzyTool[1])
			if(fuzzySummary && fuzzySummary.length) {
				return TOOLS.filter(tool => fuzzySummary.includes(tool.title))
			}
		}
		return TOOLS
	}

	getToolByTitle = (title) => {
		return TOOLS.find(tool => tool.title === title)
	}
	getToolByUrl = (url) => {
		return TOOLS.find(tool => tool.to === url)
	}

	@observable error = ""
	checkPrompt = ({value, attr}) => {
		if(filterBadWords.isProfane(value)){
			// eslint-disable-next-line no-throw-literal
			throw {
				success: false,
				attr,
				value: value.replace(/^\s+|\s+$/g, ''),
				message: "Unsafe content detected, please try different language"
			}
		}
		if(value){
			return {
				success: true,
				attr,
				value: value.replace(/^\s+|\s+$/g, ''),
			}
		}
	}
	
	checkOutput = (output) => {
		if(output){
			return output.replace(/^\s+|\s+$/g, '')
		}
		return ""
	}

	updateCredits = async (data) => {
		try {
			if(data.hasOwnProperty("data")){
				if(data.data.hasOwnProperty("credits")){
					this.profile.credits = data.data.credits
				}
				if(data.data.hasOwnProperty("creditsUsed")){
					this.profile.creditsUsed = data.data.creditsUsed
				}
			} else {
				if(data.hasOwnProperty("credits")){
					this.profile.credits = data.credits
				}
				if(data.hasOwnProperty("creditsUsed")){
					this.profile.creditsUsed = data.creditsUsed
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	@observable copyToClipboardText = ``
	copyToClipboard = (output) => {
		if (output instanceof Array) {
			output = output.join("\n")
		}
		if (!navigator.clipboard) {
			let textarea = document.getElementById('copy-textarea');
			this.copyToClipboardText = `${output}`;
			textarea.focus();
			textarea.select()
			document.execCommand('copy')
			return;
		}
		navigator.clipboard.writeText(output).then(function() {
			console.log('Async: Copying to clipboard was successful!');
		}, function(err) {
			console.error('Async: Could not copy text: ', err);
		});
	}

	@observable feedback = ``
	reportToFeedback = (output) => {
		this.redirect = "/my-profile/feedback"
		this.feedback = `${output}`
		setTimeout(()=>{ this.redirect = "" }, 50)
	}

	getQuiz = async () => {
		try {
			const body = {
				mainCategory: localStorage.getItem('main_category'),
				category:  localStorage.getItem('quiz_category'),
				topic:  localStorage.getItem('quiz_topic'),
				age: localStorage.getItem('user_age')
			};
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
				.post('/quiz/generate-questions', JSON.stringify(body), customConfig)
				.then(({ data }) => data)
			return data;
		} catch (err) {
			console.log(err)
			this.apiErrorModalOpen = true;
			return { result: [] };
		}
	}


	sendSelectedAnswer = async (question, selectedAnswer) => {
		try {
			const body = {
				category:  localStorage.getItem('quiz_category'),
				topic:  localStorage.getItem('quiz_topic'),
				bidsize: localStorage.getItem('bidSize') ? parseInt(localStorage.getItem('bidSize')): 1,
				question: question,
				selectedAnswer: selectedAnswer
			};
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
				.post('/quiz/selected-answer', JSON.stringify(body), customConfig)
				.then(({ data }) => data)
			return data;
		} catch (err) {
			console.log(err)
			this.apiErrorModalOpen = true;
			return { result: [] };
		}
	}

	setQuiz = async (questions) => {
		this.quizQuestions = questions
	}

	getCategoryTopics = async () => {
		try {
			const category =  localStorage.getItem('quiz_category');
			const body = {
				category: category
			};
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
				.post(`/quiz/categories/topics`, JSON.stringify(body), customConfig)
				.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	getMovies = async (search = '') => {
		try {
			const subcategory =  localStorage.getItem('quiz_topic');
			const body = {
				topic: search !=='' ? search : subcategory
			};
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
				.post(`/quiz/movie-db`, JSON.stringify(body), customConfig)
				.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	getMusic = async (search = '', searchBy = 'artist') => {
		try {
			const subcategory =  localStorage.getItem('quiz_topic');
			const body = {
				query: search !=='' ? search : subcategory,
				searchBy: searchBy
			};
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
			.post(`/quiz/music-db`, JSON.stringify(body), customConfig)
			.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	setHelpModal = async (show) => {
		this.helpModalOpen = show;
	}

	setWarningModal = async (show) => {
		this.warningOpen = show;
	}

	getRecentReferral = async () => {
		try {

			let data = await this.api
				.get(`/user/recent-referrals`)
				.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	getTokens = async () => {
		try {

			let data = await this.api
				.get(`/user/tokens`)
				.then(({ data }) => data)
			
			this.profile.trialGameTokens = data.trialGameTokens;
			this.profile.gameTokens = data.gameTokens;
			this.profile.onTrial = data.onTrial;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	updateProfile = async (params) => {
		try {
			const customConfig = {
				headers: { 'content-type': 'multipart/form-data' }
			};

			const response = await this.api
				.post(`/user/profile`, params, customConfig)
				.then(({ data }) => data)
			
			await this.refreshTokenAndProfile();
			return response;
		} catch (err) {
			console.log(err)
			return err;
		}
	}

	getQueryString = () => {
		return window.location.search;
	}

	getConversionRate = async () => {
		try {
			let data = await this.api
				.get(`/user/crypto/conversion/rate`)
				.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}

	submitWithdrawl = async (body) => {
		try {
			
			const customConfig = {
				headers: {
				'Content-Type': 'application/json'
				}
			};
			let data = await this.api
			.post(`/user/crypto/withdrawl-request`, JSON.stringify(body), customConfig)
			.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}
	
	getTransactionHistory = async () => {
		try {
			let data = await this.api
				.get(`/user/withdrawl/transactions`)
				.then(({ data }) => data)
			
			return data;
		} catch (err) {
			console.log(err)
			return [];
		}
	}
}


export default appStore