import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import {
	CheckIcon,
	UserIcon,
	LockClosedIcon,
} from '@heroicons/react/outline'
import {
	Switch,
	Route,
	withRouter, Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, makeObservable, } from 'mobx'
import ReCAPTCHA from "react-google-recaptcha";
import { observer, inject } from 'mobx-react'
import config from './../config';

@inject('store')
@observer
class Login extends Component {

	@observable forgotEmail = ""
	@observable newPassword = ""
	@observable confirmNewPassword = ""

	@observable email = ""
	@observable password = ""
	@observable fname = ""
	@observable lname = ""
	@observable refferalCode = ""
	@observable errorMessage = ""
	@observable successMessage = ""
	@observable termsConditions = false
	@observable over18Plus = false
	@observable recaptchaToken = null
	@observable subscribeEmail = true
	@observable showResendOption = false
	// queryString = new URLSearchParams(this.props.location.search);

	constructor() {
		super()
		makeObservable(this)
	}

	onChange = (val) => {
		this.currentPromptOption = val
	}

	onChangeAny = (val, attr) => {
		this[attr] = val
		this.errorMessage = ""
	}

	onLogin = async (e) => {
		try {
			e.preventDefault();
			if (!this.recaptchaToken) {
				this.errorMessage = "Recaptcha is required";
				return;
			}
			this.errorMessage = "";
			let data = await this.props.store.api.post('/auth/signin', {
				email: this.email,
				password: this.password,
				recaptcha_token: this.recaptchaToken
			}).then(({ data }) => data)
			this.props.store.loginWithDataTokenAndProfile(data)
		} catch (err) {
			if (err?.response?.data?.message) {
				if(err?.response?.data?.message === 'Please verify your account!' ) {
					this.showResendOption = true;
				} else  {
					this.errorMessage = err?.response?.data?.message;
				}
			}
		}
	}

	resendEmail = async (e) => {
		try {
			e.preventDefault();

			this.errorMessage = "";
			this.successMessage = "";
			let data = await this.props.store.api.post('/auth/resend-verification-email', {
				email: this.email
			}).then(({ data }) => data)

			if (data?.status) {
				this.successMessage = 'We have sent you an email to verify your account! Please check your spam folder also.';
			}
		} catch (err) {
			if (err?.response?.data?.error) {
				this.errorMessage = err?.response?.data?.error
			}
		}
	}
	
	onForgotPassword = async (e) => {
		try {
			e.preventDefault();
			if (!this.recaptchaToken) {
				this.errorMessage = "Recaptcha is required";
				return;
			}
			this.errorMessage = "";
			this.successMessage = "";
			let data = await this.props.store.api.post('/auth/forgot-password-request', {
				email: this.forgotEmail
			}).then(({ data }) => data)

			if (data?.status) {
				this.successMessage = 'We have sent you an email to reset your password! Please check your spam folder also.';
			}
		} catch (err) {
			if (err?.response?.data?.error) {
				this.errorMessage = err?.response?.data?.error
			}
		}
	}

	onResetPassword = async (e) => {
		try {
			e.preventDefault();

			if (this.newPassword == "") {
				this.errorMessage = "Password is required";
				return;
			}

			if (this.confirmNewPassword == "") {
				this.errorMessage = "Confirm password is required";
				return;
			}

			if (this.newPassword !== this.confirmNewPassword) {
				this.errorMessage = "New password and confirm password does not match!";
				return;
			}

			this.errorMessage = "";
			let data = await this.props.store.api.post('/auth/reset-password', {
				token: this.props.store.resetToken,
				password: this.newPassword
			}).then(({ data }) => data);

			if (data?.status) {
				this.successMessage = 'Password has been updated successfully! Please login to your account.';
				this.props.history.push('/login')
			}
		} catch (err) {

			if (err?.response?.data?.error) {
				this.errorMessage = err?.response?.data?.error
			}
		}
	}

	onSignup = async (e) => {
		try {

			e.preventDefault();

			if (!this.recaptchaToken) {
				this.errorMessage = "Recaptcha is required";
				return;
			}

			if (!this.termsConditions) {
				this.errorMessage = "Please confirm Terms & Conditions";
				return;
			}

			if (!this.over18Plus) {
				this.errorMessage = "Please confirm you are over 18";
				return;
			}

			if (this.props.store.referral) {
				this.refferalCode = this.props.store.referral.replace(/"/g, '');
			}

			this.errorMessage = "";
			let data = await this.props.store.api.post('/auth/signup', {
				email: this.email,
				password: this.password,
				fname: this.fname,
				lname: this.lname,
				referral: this.refferalCode,
				recaptcha_token: this.recaptchaToken,
				subscribe_email: this.subscribeEmail
			}).then(({ data }) => data)
			this.successMessage = data.message;

		} catch (err) {
			console.log(err)
			console.log(err?.response?.data?.error)
			if (err?.response?.data?.message) {
				this.errorMessage = err?.response?.data?.message
			}
		}
	}

	landingPage = () => {
		window.location.href = config.landingPageUrl;
	}

	// Currently Selected Input Option

	render() {
		return (
			<>
				<Helmet>
					<title>{`Login - Oval Pixel - Play, Learn and Earn Rewards`}</title>
				</Helmet>
				<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">

					<div className="text-center mb-6">
					<a className='link-cursor' onClick={this.landingPage}><Logo /></a>
					</div>
					<div className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}>
					{this.props.location.pathname !== "/forgot-password" && this.props.location.pathname !== "/reset-password" ?
						<div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300 ">
					
							<NavLink to="/login" className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/login" ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/login" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/login" ? "white" : "gray-100"} cursor-pointer`} >
								<div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/login" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/login" ? "green" : "gray"}`}>
									<CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/login" ? "green-600" : "gray-400"}`} aria-hidden="true" />
								</div>
								Login
							</NavLink>
							<NavLink to="/signup" className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/signup" ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/signup" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/signup" ? "white" : "gray-100"} cursor-pointer`} >
								<div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/signup" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/signup" ? "green" : "gray"}`}>
									<CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/signup" ? "green-600" : "gray-400"}`} aria-hidden="true" />
								</div>
								Signup
							</NavLink>

						</div>
						: null}
						<div className="px-4 py-4 md:px-12 md:py-12">
							{this.successMessage ? <div className="text-green-600 bg-green-100 rounded-md p-2 text-center mt-4">
								{this.successMessage}
							</div> : null}
							{this.props.store.verified === 'false' ? <div className="text-red-600 bg-red-100 rounded-md p-2 text-center mt-4 mb-2">Invalid link or Accound already verified.</div> : null}

							{this.props.store.verified === 'true' ? <div className="text-green-600 bg-green-100 rounded-md p-2 text-center mt-4 mb-2">Account verified successfully! Please login to continue.</div> : null}
							<Switch>
								<Route path="/login" >
									<Logon
										landingPageUrl={this.props.store.landingPageUrl}
										email={this.email}
										password={this.password}
										signUp={this.signUpWithGoogle}
										onChange={this.onChangeAny}
										onLogin={this.onLogin} />
								</Route>
								<Route path="/signup"  >
									<Signup
										email={this.email}
										password={this.password}
										fname={this.fname}
										lname={this.lname}
										onChange={this.onChangeAny}
										onSignup={this.onSignup}
									/>
								</Route>
								<Route path="/forgot-password"  >
									<ForgotPassword
										email={this.forgotEmail}
										onChange={this.onChangeAny}
										onForgotPassword={this.onForgotPassword}
									/>
								</Route>
								<Route path="/reset-password"  >
									<ResetPassword
										onChange={this.onChangeAny}
										onResetPassword={this.onResetPassword}
									/>
								</Route>
								<Route >
									<Redirect to="/login" />
								</Route>
							</Switch>
							{this.errorMessage ? <div className="text-red-600 bg-red-100 rounded-md p-2 text-center mt-4">
								{this.errorMessage}
							</div> : null}
							{this.showResendOption ? <><div className="text-red-600 bg-red-100 rounded-md p-2 text-center mt-4">
													Please verify your account 
													</div> <div className="p-2 text-center mt-4"><a className="text-green-500" href="javascript:void(0)" onClick={(e) => this.resendEmail(e)}>Did not receive the email? Resend email again</a></div></>	: null} 

						</div>
					</div>
				</div>
			</>)
	}
}

const Logon = observer(({ active, email, password, onChange, onLogin }) => {

	return (
		<>
			<form onSubmit={onLogin}>
				<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${(email && password) ? "green" : "gray"}-300  ${(email && password) ? "bg-green-300" : "bg-gray-300"} `}>
					<LockClosedIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${(email && password) ? "green-700" : "gray-500"}`} aria-hidden="true" />
				</div>
				<div className="mt-3 text-center ">
					<div className="text-3xl font-medium text-gray-900">
						Log in
					</div>
					<p className="text-lg text-gray-500">
						Login to your account
					</p>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e => onChange(e.target.value, 'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
						<input value={password} onChange={e => onChange(e.target.value, 'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
					</div>
					<div className="flex flex-col flex-1">
						<br />
						<ReCAPTCHA
							sitekey={config.recaptchaSitekey}
							onChange={e => onChange(e, 'recaptchaToken')}
						/>
					</div>

					<div className="flex flex-col">
						<button type="submit" className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
							Log in
						</button>
						<NavLink to="/forgot-password" className="mt-4 text-green-500 text-md">Forgot your password?</NavLink>
					</div>
				</div>
			</form>
		</>
	)
})

const Signup = observer(({ active, email, password, fname, lname, onChange, onSignup, termsConditions, over18Plus, refferalCode }) => {
	refferalCode = localStorage.getItem('referral')?.replace(/"/g, '');

	return (
		<>
			{/* onSignup */}
			<form onSubmit={onSignup}>
				<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${email && password ? "green" : "gray"}-300  ${email && password ? "bg-green-300" : "bg-gray-300"} `}>
					<UserIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${email && password ? "green-700" : "gray-500"}`} aria-hidden="true" />
				</div>
				<div className="mt-3 text-center ">
					<div className="text-3xl font-medium text-gray-900">
						Sign Up
					</div>
					<p className="text-lg text-gray-500">
						Create your account
					</p>
					<div className="md:flex">
						<div className="flex flex-col min-w-0 md:pr-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">First Name</label>
							<input value={fname} onChange={e => onChange(e.target.value, 'fname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="John" />
						</div>
						<div className="flex flex-col min-w-0 md:pl-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Last Name</label>
							<input value={lname} onChange={e => onChange(e.target.value, 'lname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="Smith" />
						</div>
					</div>
					<div className="md:flex">
						<div className="flex flex-col md:pr-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
							<input value={email} onChange={e => onChange(e.target.value, 'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
						</div>
						<div className="flex flex-col md:pl-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
							<input value={password} onChange={e => onChange(e.target.value, 'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
						</div>
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Referral Code</label>
						<input value={refferalCode} onChange={e => onChange(e.target.value, 'refferalCode')} focus="true" type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="(Optional)" />
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">
							<input onChange={e => onChange(e.target.checked, 'over18Plus')} value={over18Plus} type="checkbox" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" />
							&nbsp;I am over 18 years old
						</label>
					</div>

					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">
							<input onChange={e => onChange(e.target.checked, 'termsConditions')} value={termsConditions} type="checkbox" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" />
							&nbsp;I have read and agreed to the <a href="https://ovalpixel.com/terms-and-conditions" className="text-green-500" target="_blank">Terms & Conditions</a>
						</label>
					</div>

					<ReCAPTCHA
						sitekey={config.recaptchaSitekey}
						onChange={e => onChange(e, 'recaptchaToken')}
					/>

					<div className="flex flex-col">
						<br />
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left"><input type="checkbox" onChange={e => onChange(e.target.checked, 'subscribeEmail')} defaultChecked={true} /> Receive occasional communications from OvalPixel <br />
							<a href="https://ovalpixel.com/privacy-policy" className="text-green-500" target="_blank">We respect your privacy</a>

						</label>


					</div>

					<div className="flex flex-col">
						<button type="submit" className="hover:bg-green-600 bg-green-500 font-medium rounded-lg text-lg px-4 py-2 bg-gray-200 text-white mt-4 border border-gray-300 inline-block" >
							Sign Up
						</button>
					</div>
				</div>
			</form>
		</>
	)
})


const ForgotPassword = observer(({ active, email, onChange, onForgotPassword }) => {

	return (
		<>
			<form onSubmit={onForgotPassword}>
				<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${(email) ? "green" : "gray"}-300  ${(email) ? "bg-green-300" : "bg-gray-300"} `}>
					<LockClosedIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${(email) ? "green-700" : "gray-500"}`} aria-hidden="true" />
				</div>
				<div className="mt-3 text-center ">
					<div className="text-3xl font-medium text-gray-900">
						Reset Password
					</div>
					<p className="text-lg text-gray-500">
						We'll email you instructions to reset your password.
					</p>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e => onChange(e.target.value, 'forgotEmail')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>

					<div className="flex flex-col flex-1">
						<br />
						<ReCAPTCHA
							sitekey={config.recaptchaSitekey}
							onChange={e => onChange(e, 'recaptchaToken')}
						/>
					</div>

					<div className="flex flex-col">
						<button type="submit" className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
							Reset Password
						</button>
						<NavLink to="/login" className="mt-4 text-gray-400 text-sm">Back to Login</NavLink>
					</div>
				</div>
			</form>
		</>
	)
})

const ResetPassword = observer(({ onChange, onResetPassword }) => {

	return (
		<>
			<form onSubmit={onResetPassword}>
				<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-300   `}>
					<LockClosedIcon className={`h-8 w-8 text-green-700`} aria-hidden="true" />
				</div>
				<div className="mt-3 text-center ">
					<div className="text-3xl font-medium text-gray-900">
						Reset Password
					</div>
					<p className="text-lg text-gray-500">
						Change your password!
					</p>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">New Password</label>
						<input  onChange={e => onChange(e.target.value, 'newPassword')} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>

					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Confirm Password</label>
						<input  onChange={e => onChange(e.target.value, 'confirmNewPassword')} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>

					<div className="flex flex-col">
						<button type="submit" className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
							Change Password
						</button>
						<NavLink to="/login" className="mt-4 text-gray-400 text-sm">Back to Login</NavLink>
					</div>
				</div>
			</form>
		</>
	)
})

const Logo = () => (
	<a href="https://ovalpixel.com/"><img src="./logo_with_text.svg" className="w-60 inline-block" /></a>
)



export default withRouter(Login)