import React from 'react';
import {
    PencilIcon,
    UserCircleIcon
  } from '@heroicons/react/outline';
import {Helmet} from "react-helmet";
import Header from '../Components/Header';
import Body,{ Grid, Col } from '../Components/Body';
import Button from '../Components/Button';
import Countdown from 'react-countdown';
import { withRouter } from 'react-router-dom'
import { observable } from 'mobx'
import { observer, inject,  } from 'mobx-react'
import styled from 'styled-components'
import config from './../config';

import winSound from "../assets/win.mp3";
import looseSound from "../assets/loose.mp3";
import tickSound from "../assets/ticking.mp3";
import Loader from '../Components/Loader';
import Popover from '@idui/react-popover';
import Modal from 'react-modal';

@inject('store')
@observer
class Quiz extends React.Component {
    state = {
        quizTitle: '',
        date: Date.now() + 15000,
        activeQuestionIndex: 0,
        currentQuestionNo: 1,
        questionsCount: 0,
        selectedAnswerIndex: null,
        isWrongAnswer: false,
        isRightAnswer: false,
        showResult: false,
        modalIsOpen: false,
        maxQuestionModalOpen: false,
        result: {
            score: 0,
            correctAnswers: 0,
            wrongAnswers: 0
        },
        gameTokens: 0,
        bidSize: 1,
        volume: true,
        loading: true,
        increaseBidTip: false,
        isMobile: false,
        disableOptions: false,
        quizQuestion: {
          category: 'Movies',
          topic: 'John Wick',
          totalQuestions: 5,
          perQuestionScore: 5,
          questions: [],
        }
    };



    @observable tool = {}

	@observable.deep prompts = []
	@observable currentPrompt = 0
	@observable currentOption = "Start Using"
    
  @observable error = ""

  @observable output = ""
	@observable outputs = []
  @observable code = ""


    
	// @observable date = Date.now() + 15000
    countdown = []
    isAnswerSelected = false
    winAudio = new Audio(winSound);
    looseAudio = new Audio(looseSound);
    tickAudio = new Audio(tickSound);
    countdownApi = null;

    modalStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
          height: '305px'
      }
    };

    helpModalStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
          height: '335px'
      }
    };

    warningModalStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
          height: '150px'
      }
    };

    apiErrorModalStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width:  window.innerWidth <= 768 ?'100%':'600px',
          height: '190px'
      }
    };

    constructor(props) {
        super(props)
        this.state.quizQuestion.category = localStorage.getItem('quiz_category');
        this.state.quizQuestion.topic = localStorage.getItem('quiz_topic');
        this.state.quizTitle = `Now Playing under ${this.state.quizQuestion.category} - Topic: ${this.state.quizQuestion.topic}`; 
    }

    componentDidMount() {

      // disable right click 
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      //check to see if it currently on mobile
      this.setState({isMobile: window.innerWidth <= 768});

      const gameTokens = this.props.store.profile.onTrial ? this.props.store.profile.trialGameTokens : this.props.store.profile.gameTokens;
      const bidSize = localStorage.getItem('bidSize') ? parseInt(localStorage.getItem('bidSize')): 1;
      this.setState({gameTokens: gameTokens, bidSize: bidSize});

      try {
        if (gameTokens <= 0) {
          this.setState({modalIsOpen: true, loading: false});
          this.tickAudio.pause();
          this.winAudio.pause();
          this.looseAudio.pause();
          this.setState({date: 0});
        } else{
          // const quiz = this.state.quizQuestion;
          // quiz.questions = [{"question":"1. What is the most popular gaming console?","options":["a. Playstation 4","b. Xbox","c. Gamecube","d. Sega"],"answer":""},{"question":"2. What is the most popular game genre?","options":["a. RPG","b. Action","c. Adventure","d. Strategy"],"answer":""},{"question":"3. Which game was the first to offer online multiplayer?","options":["a. Quake","b. StarCraft","c. Counter-Strike","d. Ultima Online"],"answer":""},{"question":"4. Who is the main protagonist in the game The Legend of Zelda?","options":["a. Mario","b. Link","c. Samus","d. Pikachu"],"answer":""},{"question":"5. What was the first commercially available home video game console?","options":["a. Nintendo Entertainment System","b. Magnavox Odyssey","c. Sega Master System","d. Atari 2600"],"answer":""},{"question":"6. What is the best-selling single video game of all time?","options":["a. Grand Theft Auto V","b. Super Mario Bros.","c. Minecraft","d. Tetris"],"answer":""},{"question":"7. What is the most popular mobile gaming platform?","options":["a. iOS","b. Android","c. Windows Phone","d. Blackberry"],"answer":""},{"question":"8. What is the most widely played online game?","options":["a. Fortnite"],"answer":""}];
          // ;
          // this.setState({quizQuestion: quiz});
          // this.setState({date: Date.now() + 15000});

          // this.tickAudio.play();
          // this.tickAudio.loop = true;
          // this.setState({loading: false});

        this.props.store.getQuiz().then((response) => {
          const quiz = this.state.quizQuestion;
          quiz.questions = response.result;
          const questionsCount = quiz.questions.length;
          this.setState({quizQuestion: quiz, questionsCount: questionsCount});
          this.setState({date: Date.now() + 15000});
          
          this.tickAudio?.play();
          this.tickAudio.loop = true;
          this.setState({loading: false});
        });
    }

      } catch (err){
        console.log(err)
      }
    }

    componentWillUnmount = () => {
      this.setState({});
      localStorage.removeItem('quiz_topic');
      this.tickAudio.pause();
      this.tickAudio.removeAttribute('src');
      this.tickAudio.load();

      this.winAudio.pause();
      this.winAudio.removeAttribute('src');
      this.winAudio.load();

      this.looseAudio.pause();
      this.looseAudio.removeAttribute('src');
      this.looseAudio.load();
      if (this.state.gameTokens > 0) {
      this.countdownApi?.stop();
      }
    }

    onAnswerSelected =  (answer, index) => {
      if (!this.state.disableOptions) {
        this.setState({selectedAnswerIndex: index});
      }
    }

    timeOutQuestion = () => {
        if (this.state.quizQuestion.questions.length > 0) {
          this.onClickNext(true);
        }
    }

    buyMore = () => {
      window.location.href = `${config.landingPageUrl}/shop/`;
    }

    playOtherTopics = () => {
      this.props.history.push('/');
    }

    playMoreQuestions = () => {
      this.props.store.getQuiz().then((response) => {
        const quiz = this.state.quizQuestion;
        quiz.questions = response.result;
        const questionsCount = (this.state.questionsCount + response.result.length);
        const totalToken = this.state.gameTokens - 10;
        this.setState({gameTokens: totalToken, maxQuestionModalOpen: false ,quizQuestion: quiz, activeQuestionIndex: 0, selectedAnswerIndex: null, date: (Date.now() + 15000), questionsCount: questionsCount, currentQuestionNo: 1, disableOptions: false});
        this.countdownApi?.start();
        this.tickAudio.play();
        this.tickAudio.loop = true;
        this.setState({loading: false});
      });
    }

    onClickNext = (timeOut = false) => {

        // if game tokens are empty
        if (this.state.gameTokens <= 0) {
          this.tickAudio.pause();
          this.winAudio.pause();
          this.looseAudio.pause();
          this.countdownApi?.stop();
          this.setState({modalIsOpen: true});
          if (this.props.store.profile.onTrial) {
            this.props.store.switchToRealAccount();
          }
        }
        //if user have reached topic limit
        else if (this.state.currentQuestionNo === config?.questionsPerTopic) {
          this.setState({maxQuestionModalOpen: true, loading: false});
          this.tickAudio.pause();
          this.winAudio.pause();
          this.looseAudio.pause();
          this.setState({date: 0});
          this.countdownApi?.stop();
        } 
    else {
            this.setState({disableOptions: true});
            this.countdownApi?.stop();
  
            //if not muted pause audio
            if (this.state.volume) { 
              this.tickAudio.pause();
            }
            
            this.caclScore(timeOut);

            setTimeout(() => {
              // if all questions are loaded fetch new ones
              const nextIndex = this.state.activeQuestionIndex+1;
              if (nextIndex === this.state.quizQuestion.questions.length) {
                    this.setState({loading: true, isRightAnswer: false, isWrongAnswer: false,});
                    this.countdownApi?.stop();
                    this.tickAudio.pause();

                    this.props.store.getQuiz().then((response) => {
                      const quiz = this.state.quizQuestion;
                      quiz.questions = response.result;
                      const questionsCount = (this.state.questionsCount + response.result.length);

                      this.setState({isRightAnswer: false, isWrongAnswer: false, quizQuestion: quiz, activeQuestionIndex: 0, selectedAnswerIndex: null, date: (Date.now() + 15000), questionsCount: questionsCount, currentQuestionNo: (this.state.currentQuestionNo+1), disableOptions: false});
                      this.countdownApi?.start();
                      this.tickAudio.play();
                      this.tickAudio.loop = true;
                      this.setState({loading: false});

                    });
              
                  // this.setState({showResult: true});
              } else {
                  this.setState({isRightAnswer: false, isWrongAnswer: false, activeQuestionIndex: nextIndex, selectedAnswerIndex: null, date: (Date.now() + 15000), currentQuestionNo: (this.state.currentQuestionNo+1), disableOptions: false});
                  this.countdownApi.start();

                  if (this.state.volume) { 
                    this.tickAudio.play();
                    this.tickAudio.loop = true;
                  }
              }
            }, 5000);
        }
    }


    caclScore = (timeOut = false) => {

      //update selected answer
      this.props.store.sendSelectedAnswer(this.getCurrentQuestion(), this.getCurrentAnswer()).then((result) => {
        
      });

        if (this.getCorrectAnswer() === this.getCurrentAnswer() && !timeOut) {
            if (this.state.volume) { 
              this.winAudio.play();
            }
            this.setState({isRightAnswer: true});
            let result = this.state.result;
            result.correctAnswers += 1;
            result.score += parseInt(this.state.bidSize);

            const totalToken = this.state.gameTokens + parseInt(this.state.bidSize);
            if (this.props.store.profile.onTrial ) {
              this.props.store.profile.trialGameTokens += parseInt(this.state.bidSize);
            } else {
              this.props.store.profile.gameTokens += parseInt(this.state.bidSize);
            }

            this.setState({result, gameTokens: totalToken});
            
            // if already not on bidsize
            if (this.state.bidSize !== 25) {
              this.setState({increaseBidTip: true});
            }
            localStorage.setItem('game_tokens', totalToken);


            setTimeout(() => {
              if (this.state.bidSize !== 25) {
                this.setState({increaseBidTip: false});
              }
            }, 4000)
        } else {
            if (this.state.volume) {
              this.looseAudio.play();
            }
            this.setState({isWrongAnswer: true});
            let result = this.state.result;
            result.wrongAnswers += 1;
            this.setState({result:result});

            const totalToken = this.state.gameTokens - parseInt(this.state.bidSize);
            if (this.props.store.profile.onTrial ) {
              this.props.store.profile.trialGameTokens -= parseInt(this.state.bidSize);
            } else {
              this.props.store.profile.gameTokens -= parseInt(this.state.bidSize);
            }
            this.setState({gameTokens: totalToken});
            localStorage.setItem('game_tokens', totalToken);
        }

    }

    getCurrentQuestion = () => {
      return this.state.quizQuestion.questions[this.state.activeQuestionIndex].question;
  }

    getCurrentAnswer = () => {
        return this.state.quizQuestion.questions[this.state.activeQuestionIndex].options[this.state.selectedAnswerIndex];
    }

    getCorrectAnswer = () => {
        return this.state.quizQuestion.questions[this.state.activeQuestionIndex].answer;
    }

    toggleSound = () => {
      this.setState({volume: !this.state.volume});
      this.tickAudio.pause();
    }



    openClassName = (index) => {
      let optionClassName = this.state.selectedAnswerIndex === index ? 'selected-answer disable-text-selection' : "";
      optionClassName += this.state.selectedAnswerIndex === index && this.state.isWrongAnswer ? 'wrong-answer disable-text-selection' : "";
      optionClassName += this.state.selectedAnswerIndex === index && this.state.isRightAnswer ? 'right-answer disable-text-selection' : "";

      return (optionClassName ? optionClassName:'disable-text-selection');
    }

    setRef = (countdown) => {
      if (countdown) {
        this.countdownApi = countdown.getApi();
      }
    };

    updateBidSize = (e) => {
      this.countdownApi.stop();
      this.setState({bidSize: parseInt(e.target.value)});
      localStorage.setItem('bidSize', parseInt(e.target.value));
      const nextIndex = this.state.activeQuestionIndex+1;
      if (nextIndex === this.state.quizQuestion.questions.length) {
        this.onClickNext();
        return;
      }

      this.setState({isRightAnswer: false, isWrongAnswer: false, activeQuestionIndex: nextIndex, selectedAnswerIndex: null, date: (Date.now() + 15000), currentQuestionNo: (this.state.currentQuestionNo+1), disableOptions: false});
      this.countdownApi.start();
    }
  

        render() {

       
            return(
                <>
                <Helmet>
                    <title>{`Oval Pixel - Play, Learn and Earn Rewards`}</title>
                </Helmet>
                <Header 
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={UserCircleIcon}
                    fromColor={this.tool.fromColor}
                    category={this.tool.category}
                    profilePicture={this.props.store.profile.profile_picture}

                    options={[
                        {   title:  this.state.quizTitle, 
                            Icon: PencilIcon, 
                            color: this.props.store.profile.credits ? 'green' : 'red',
                            onClick: this.onStartUsing,
                            isQuizPage: true
                        }
                    ]}
                    currentOption={this.currentOption}
                />
                <Body className="flex flex-1">
                    <Grid>
                            <Col span="6">
                                <div className="quiz-sidebar">
                                  <ul className="sidenav">
                                      <li>
                                      <Popover className={this.state.isWrongAnswer ?"game-token-removed" : "game-token-added"} isOpen={(this.state.isRightAnswer || this.state.isWrongAnswer) && !this.state.isMobile} placement="right" content={this.state.isRightAnswer ? `Hooray! You won ${this.state.bidSize} tokens 🥳`: `Oops! wrong answer you've lost ${this.state.bidSize} token 🤯`}>
                                           <button>{this.props.store.profile.onTrial ? 'Trial' : 'Game'} Tokens</button>
                                      </Popover>
                                        <br/><span className="game-tokens">{this.state.gameTokens}</span>
                                        <br/>     <a className="buy-more-text" onClick={() => this.buyMore()}>
                                          Buy More
                                        </a>
                                        </li>
                                 
                                      <li>
                                      <Popover isOpen={this.state.increaseBidTip && !this.state.isMobile} placement="right" content="Hi! Increase your bid size to win more on each correct answer :)">
                                           <button>Bid Size</button>
                                      </Popover>
                           
                                      <br/>
                                      <Popover className="how-it-works-content" isOpen={this.state.howItWorksPopup  && !this.state.isMobile} placement="right" content="Changing your bid size will skip the current displayed question.">  
                                      <span className="game-tokens" style={{marginLeft: '0px'}}>
                                        <select className="bid-size-dropdown" 
                                                  value={this.state.bidSize} 
                                                  onChange={this.updateBidSize}>
                                                <option value="1">1</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                      </select>
                                    </span>
                                      </Popover> 
                                   
                                      </li>
                                      <li>
                                      {this.state.volume ?
                                      (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 sound-icon" onClick={() => this.toggleSound()}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                                      </svg>) :
                                        (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 sound-icon" onClick={() => this.toggleSound()}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
                                        </svg>)
                                      }
                                      </li>
                                     
                                  </ul>
                                </div>
                                  <div className="quiz-sidebar-mobile">
                                    <ul className="sidenav text-center">
                                        <li>
                                        <Popover className={this.state.isWrongAnswer ?"game-token-removed" : "game-token-added"} isOpen={(this.state.isRightAnswer || this.state.isWrongAnswer) && this.state.isMobile} placement="right" content={this.state.isRightAnswer ? `Hooray! You won ${this.state.bidSize} tokens 🥳`: `Oops! wrong answer you've lost ${this.state.bidSize} token 🤯`}>
                                            <button>Games Tokens</button>
                                        </Popover>
                                          <br/><span className="game-tokens">{this.state.gameTokens}</span>
                                       
                                          </li>
                                  
                                        <li>
                                        <Popover isOpen={this.state.increaseBidTip && this.state.isMobile} placement="bottom" content="Hi! Increase your bid size to win more on each correct answer :)">
                                            <button>Bid Size</button>
                                        </Popover>
                            
                                        <br/>
                                        <Popover isOpen={this.state.howItWorksPopup  && this.state.isMobile} placement="bottom" content="You can increase/decrease your bid size by ending the game and returning to the select a topic screen.">  
                                          <span className="game-tokens">{this.state.bidSize}</span>
                                        </Popover>

                 
                                    
                                        </li>
                                        <li>
                                        {this.state.volume ?
                                        (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 sound-icon" onClick={() => this.toggleSound()}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                                        </svg>) :
                                          (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 sound-icon" onClick={() => this.toggleSound()}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
                                          </svg>)
                                        }
                                        </li>
                                    </ul>
                                  </div>
                            </Col>
                            <Col span="6">
                              { this.state.loading ?
                              (<Loader active className="w-20 h-20 mr-4" style={{ position: "absolute", display: "block", top: "45%", left: "45%"}} /> 
                                ): ( 
                                  <div>
                                <Grid>
                                    <Col span="4" className="sm:hidden"></Col>
                                    <Col className="countdown-container sm:col-span-12">
                                            <Countdown    
                                                ref={this.setRef}
                                                date={this.state.date} 
                                                renderer={props => 
                                                <Button 
                                                    title={props.total ? `${props.total/1000} secs` : "Timeout"}
                                                    className="countdown-container-button"   
                                                />} 
                                                onComplete={() => this.timeOutQuestion()}
                                                /> 
                                    </Col>
                                 
                                </Grid>
                           
                            <div className="quiz-container">
                            {!this.state.showResult && this.state.quizQuestion.questions.length > 0 ? (
                                    <div>
                                    <div>
                                        <span className="active-question-no">
                                        {this.state.currentQuestionNo}
                                        </span>
                                       
                                    </div>
                                    <h2
                                    className="disable-text-selection"
                                    onPaste={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }} onCopy={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }} onCut={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }} >{ this.state.quizQuestion.questions[this.state.activeQuestionIndex].question.replace(/\d*\./, '') } </h2>
                                    <ul>
                                    {this.state.quizQuestion.questions[this.state.activeQuestionIndex].options.map((answer, index) => (
                                        <li  
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }} onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }} onCut={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }}
                                            key={answer}
                                            onClick={() => this.onAnswerSelected(answer, index)}                      
                                            className={
                                              this.openClassName(index) 
                                              }>
                                           {answer}
                                        </li>
                                    ))}
                                    </ul>
                                    <div className="flex-right">
                                        <button
                                            onClick={() => this.onClickNext()}
                                            disabled={this.state.disableOptions}
                                        >
                                        Next
                                        </button>
                                    </div>
                                    </div>
                                       ) : (
                                        <div className="result">
                                          <h3>Result</h3>
                                          <p>
                                            Total Question: <span>{this.state.quizQuestion.questions.length}</span>
                                          </p>
                                          <p>
                                            Total Score:<span> {this.state.result.score}</span>
                                          </p>
                                       
                                        </div>
                                      )
                                    }
                                    </div>
                                    </div>
                              )
                            }
                            </Col>
                    </Grid>

                    <Modal
                          isOpen={this.state.modalIsOpen}
                          style={this.modalStyles}
                          contentLabel="Oops! Out of Game Tokens"
                          >
                          <h2 className="text-center font-semibold text-gray-500">Oops! You're out of Game Tokens or you have not yet purchased any game tokens.</h2>
                          <h3  className="text-center font-semibold text-green-500">You will need to buy more Game Tokens to continue playing. <br/> Don't worry… you have the choice and opportunity to earn back any lost tokens. Or switch to trial mode to play with your free tokens</h3>
                        <div className="grid justify-items-center">
                                  <button 
                        onClick={this.buyMore}
                                      
                className={
                  `select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
                  >			
                  Buy More</button>
                  <button 
                        onClick={this.playOtherTopics}              
                        className={
                  `select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
                  >Back to Dashboard</button>
                          </div>
                </Modal>
                <Modal
                          isOpen={this.state.maxQuestionModalOpen}
                          style={this.modalStyles}
                          contentLabel="You Have Played Maximum Questions!"
                          >
                          <h2 className="text-center font-semibold text-gray-500">You have reached Maximum Questions limit of {config.questionsPerTopic} for this topic!</h2>
                          
                          <h3 className="text-center font-semibold text-green-500"> To play additional 100 questions you would need to deposit 10 tokens.</h3>
                        <div className="grid justify-items-center">
                        <button 
                        onClick={this.playMoreQuestions}              
                        className={
                  `select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
                  >Click here to deposit 10 tokens & Play More Questions</button>
                        <button 
                        onClick={this.playOtherTopics}              
                        className={
                  `select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
                  >Choose another Topic or Category</button>
                          </div>
                </Modal>
                <Modal
                    isOpen={this.props.store.helpModalOpen}
                    style={this.helpModalStyles}
                    >
                        <h2 className="text-center font-semibold text-gray-500">Trial Mode</h2>
                        <h3  className="text-center text-green-500">On signup you are given free game tokens to play and get familiar with the game. You can play for free until you use up all your trial tokens. <br />Note: You cannot withdraw your trial winning tokens. </h3>
                        <br />
                        <h2 className="text-center font-semibold text-gray-500">Game Mode</h2>
                        <h3  className="text-center  text-green-500">You would need to buy tokens in order to play in game mode. You can win or lose tokens by playing the game. You can only withdraw your winning tokens.</h3>
                        <div className="grid justify-items-center">
                                <button onClick={(e) => { this.props.store.setHelpModal(false); }}
                                className={`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } >			
                                Ok</button>
                        </div>
			          </Modal>

                <Modal
                    isOpen={this.props.store.warningOpen}
                    style={this.warningModalStyles}
                    >
                        
                        <h3  className="text-center text-green-500">Please end the game to switch between Trial & Game Modes. </h3>
                        <br />
              
                        <div className="grid justify-items-center">
                                <button onClick={(e) => { this.props.store.setWarningModal(false); }}
                                className={`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } >			
                                Ok</button>
                        </div>
			          </Modal>

                <Modal
                    isOpen={this.props.store.apiErrorModalOpen}
                    style={this.apiErrorModalStyles}
                    >
                        
                        <h3  className="text-center text-green-500">Oops something went wrong. Please try again in a few seconds or simply refresh the page. </h3>
                        <br />
              
                        <div className="grid justify-items-center">
                                <button onClick={(e) => { this.props.store.apiErrorModalOpen = false; this.playOtherTopics(); }}
                                className={`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } >			
                                Back to Dashboard</button>
                        </div>
			          </Modal>
                </Body>
            </>
        )
    }
}

const Tooltip = styled.div`
	display:none;
	white-space: nowrap;
`



export default withRouter(Quiz)