import React, { Component } from 'react';
import {
    ClockIcon,
    InformationCircleIcon
  } from '@heroicons/react/outline'
import {Helmet} from "react-helmet";
import Header from '../Components/Header'
import Body, { Grid, Col } from '../Components/Body'
import Button from '../Components/Button';
import { withRouter } from 'react-router-dom';
import { observable, makeObservable } from 'mobx';
import { observer, inject,  } from 'mobx-react';
import EntryTabs from '../Components/EntryTabs';
import Modal from 'react-modal';
import Select from 'react-select';
import { CheckIcon,  } from '@heroicons/react/outline';
import moment from 'moment';
@inject('store')
@observer
class Tool extends Component {

    @observable tool = {}

	@observable.deep prompts = []
	@observable currentPrompt = 0
	@observable currentOption = "Start Using"
    
    @observable error = ""

    @observable output = ""
	@observable outputs = []
    @observable code = ""


    @observable loading = false
    
	@observable date = Date.now() + 1000
    countdown = []
    queryString = new URLSearchParams(this.props.location.search);
    state = {
        modalIsOpen: false,
        topics: [],
        enableMovieDropdown: false,
        movies: [],
        movieSearchValue: '',
        musicSearchBy: 'artist',
        bidSize: 1,
    }
    ageList = []

    modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: '350px'
        }
    }

    helpModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            height: '335px'
        }
    }

    dropdownStyle = { menuList: (base) => ({ ...base, position: 'fixed !important', backgroundColor: 'white', border: '1px solid lightgray',  zIndex: 9999 })}

    constructor(props) {
        super(props)
        makeObservable(this)

        this.tool = this.props.store.getToolByUrl(this.props.location.pathname)
        if(!this.tool){
			window.location.href = '/';
		} else {
            this.prompts = [...this.tool.prompts]
        }

        this.tool.title = this.queryString.get('category').replace(';', '&');
        
        for (let i=18;i<=70;i++) {
            this.ageList.push({
                label: i,
                value: i,
                key: i
            });
        }
    }

    componentDidMount() {
        localStorage.setItem('quiz_category', this.queryString.get('category').replace(';', '&'));
        localStorage.setItem('main_category', this.queryString.get('parent'));
        this.props.store.getCategoryTopics().then((response) => {
            const topicOptions = response.map((item, index) => {
                return  {
                    label: item,
                    value: item,
                    key: `topic-${index}`
                }
            })
            this.setState({ topics:  topicOptions });
        });

        const bidSize = localStorage.getItem('bidSize') ? parseInt(localStorage.getItem('bidSize')): 1;
        this.setState({bidSize: bidSize});
  
    }

    onGenerateClick = () => {
        this.error = "";
        // if (localStorage.getItem('quiz_topic')?.toLowerCase() === localStorage.getItem('old_quiz_topic')?.toLowerCase()) {
        //     this.error = "You have already played this topic. Please try another one.";
        // } else {
            this.state.modalIsOpen = true;
            this.setState(this.state);
        // }
    }

    submitAge =  () => {
        this.state.modalIsOpen = false;
        if (!localStorage.getItem('quiz_topic')) {
            localStorage.setItem('quiz_topic',  localStorage.getItem('quiz_category'))
        }
        localStorage.setItem('old_quiz_topic', localStorage.getItem('quiz_topic'));
        this.props.history.push('/quiz');
    }

    getReleaseDate = (movie) => {
        if (movie?.release_date) {
            return ` - Release Date: ${moment(movie.release_date).format('MM/DD/YYYY')}`;
        } else if (movie?.first_air_date) {
            return ` - Release Date: ${moment(movie.first_air_date).format('MM/DD/YYYY')}`;
        } else if (movie?.known_for) {
            return ` - Release Date: ${moment(movie.known_for[0].release_date).format('MM/DD/YYYY')}`;
        }

        return  '';
    }

    topicSelected = (item) => {
        localStorage.setItem('quiz_topic', item.value);
        this.setState({movieSearchValue: ''});

        if (this.queryString.get('category').replace(';', '&') === 'Movies') {
            this.setState({enableMovieDropdown: true});
            this.props.store.getMovies().then(moviesResult => {
                if (moviesResult.length > 0) {
                    let moviesItems = moviesResult.map((movie) => {
                        if (movie.title) {
                            return  {
                                label: movie.title+this.getReleaseDate(movie),
                                value: movie.title,
                                key: `movie-${movie.id}`
                            }
                        } else if (movie.original_title) {
                            return  {
                                label: movie.original_title+this.getReleaseDate(movie),
                                value: movie.original_title,
                                key: `movie-${movie.id}`
                            }
                        } else if (movie.name) {
                            return  {
                                label: movie.name+this.getReleaseDate(movie),
                                value: movie.name,
                                key: `movie-${movie.id}`
                            }
                        }

                    });
                    this.setState({movies: moviesItems});
                } else {
                    this.setState({movies: []});
                }
            });
        }

        if (this.queryString.get('category').replace(';', '&') === 'Music') {
            this.setState({enableMusicDropdown: true});
            this.searchMusic(item.value);
        }

    }

    searchMovies = (search) => {

        const filteredMovies = this.state.movies.filter((movie) => movie.title === search);
        if (filteredMovies.length > 0 || !search) return; 

        this.props.store.getMovies(search).then(moviesResult => {
            if (moviesResult.length > 0) {
                let moviesItems = moviesResult.map((movie) => {
                    if (movie.title) {
                        return  {
                            label: movie.title+this.getReleaseDate(movie),
                            value: movie.title,
                            key: `movie-${movie.id}`
                        }
                    } else if (movie.original_title) {
                        return  {
                            label: movie.original_title+this.getReleaseDate(movie),
                            value: movie.original_title,
                            key: `movie-${movie.id}`
                        }
                    } else if (movie.name) {
                        return  {
                            label: movie.name+this.getReleaseDate(movie),
                            value: movie.name,
                            key: `movie-${movie.id}`
                        }
                    }

                });
                this.setState({movies: moviesItems});
            }
        });
    }

    getArtistName = (artist) => {
        if (typeof artist !== 'undefined') {
           return ' - Artist: '+artist[0]['name']; 
        }   

        return '';
    }


    getArtistDesc = (artist) => {
        if (typeof artist['disambiguation'] !== 'undefined') {
           return '  ('+artist['disambiguation']+')'; 
        }   

        return '';
    }

    searchMusic = (search) => {

        const filteredMovies = this.state.movies.filter((movie) => movie.title === search);
        if (filteredMovies.length > 0 || !search) return; 

        this.props.store.getMusic(search, this.state.musicSearchBy).then(musicResult => {
            if (musicResult.length > 0) {
                let musicItems = musicResult.map((music) => {
                    if (this.state.musicSearchBy === 'artist') {
                        return  {
                            label: music.name+this.getArtistDesc(music),
                            value: music.name+' Artist',
                            key: `music-${music.id}`
                        }
                    } else {
                        return  {
                            label: music.title+this.getArtistName(music['artist-credit']),
                            value: music.title+' Track',
                            key: `music-${music.id}`
                        }
                    }


                });
                this.setState({movies: musicItems});
            }
        });
    }

    clearSelection = () => {
        this.setState({movieSearchValue: ''})
    }

    subCategorySelected = (selection) => {
        localStorage.setItem('quiz_topic', selection.value);
        this.setState({movieSearchValue: selection})
    }

    selectAge = (e) => {
        localStorage.setItem('user_age', e.target.value);
    }

    changeSearchBy = (value) => {
        this.setState({musicSearchBy: value, movieSearchValue: '', movies: []});
    }

    updateBidSize = (e) => {
        this.setState({bidSize: parseInt(e.target.value)});
        localStorage.setItem('bidSize', parseInt(e.target.value));
    }

        render() {

            // required for mobx to pick up deeply nested value 
            const currentValue = this.prompts[this.currentPrompt].prompts[0].value

            return(
                <>
                <Helmet>
                    <title>{`Oval Pixel - Play, Learn and Earn Rewards`}</title>
                </Helmet>
                <Header 
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={this.tool.Icon}
					fromColor={this.tool.fromColor}
					category={this.tool.category}
                    currentOption={this.currentOption}
                    profilePicture={this.props.store.profile.profile_picture}
                />
                <Body>
                        <Grid>
                            <Col span="6" className="select-topic-container">

                                <EntryTabs
                                    prompts={this.prompts}
                                    currentPrompt={this.currentPrompt}
                                    onChange={this.handleCurrentPrompt}
                                />

                 
                                        <div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8 `}>

                                            <div className="px-6 py-6">

                                                <div className="flex items-center">

                                                    <div className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-green-300 sm:mx-0 sm:h-10 sm:w-10 `}>
                                                        
                                                        <CheckIcon 
                                                            className={`h-3 w-3 md:h-6 md:w-6 ext-green-700 text-green-700`} aria-hidden="true" />

                                                    </div>

                                                    <div className="mt-0 ml-4 text-left">
                                                        <div as="h3" className="text-lg leading-6 font-medium text-gray-900">

                                                        Please select a topic which you want to play in the category

                                                        </div>

                                                     
                                                    </div>

                                                </div>
                                                    <Select
                                                     menuPortalTarget={document.body}
                                                     styles={this.dropdownStyle}
                                                     className="topic_dropdown"
                                                      placeholder="Please select a topic"
                                                     onChange={this.topicSelected}
                                                     options={this.state.topics}
                                                     menuPlacement="bottom"
                                                     />
                                                     
                                                    { this.state.enableMovieDropdown ?
                                                    (<>
                                                    <Select
                                                     menuPortalTarget={document.body}
                                                     styles={this.dropdownStyle}
                                                     className="topic_dropdown"
                                                      placeholder="Please select or search a movie, tv show, music or celebrity"
                                                     onChange={this.subCategorySelected}
                                                     options={this.state.movies}
                                                     menuPlacement="bottom"
                                                     onInputChange={this.searchMovies}
                                                     value={this.state.movieSearchValue}
                                                     />
                                                     <a onClick={this.clearSelection} className="clear-selection-btn text-blue-500">Clear Selection</a>
                                                     </>
                                                     ): (null)}

                                                    { this.state.enableMusicDropdown ?
                                                    (<>
                                                    <Select
                                                     menuPortalTarget={document.body}
                                                     styles={this.dropdownStyle}
                                                     className="topic_dropdown"
                                                     placeholder="Please select or search an artist or track or album"
                                                     onChange={this.subCategorySelected}
                                                     options={this.state.movies}
                                                     menuPlacement="bottom"
                                                     onInputChange={this.searchMusic}
                                                     value={this.state.movieSearchValue}
                                                     />
                                                     <a onClick={this.clearSelection} className="clear-selection-btn text-blue-500">Clear Selection</a>
                                                     &nbsp;<label><input type="radio" name="search_by" value="artist" onClick={(e)=>this.changeSearchBy(e.target.value)} defaultChecked={true}/> &nbsp;Search by Artist</label>
                                                     &nbsp;<label><input type="radio" name="search_by" value="track" onClick={(e)=>this.changeSearchBy(e.target.value)}/> &nbsp;Search by Track</label>
                                                     </>
                                                     ): (null)}

                                                     <div className="md:flex">
                                                         <Button 
                                                             title="Play Game" 
                                                             Icon={ClockIcon} 
                                                             onClick={this.onGenerateClick} 
                                                         />
                                                         
                                                     </div>
                                                    {this.error && <div className="mt-4"><label 
                                                        className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>
                                                            {this.error}
                                                    </label></div>}
                                                                                
                                            </div>
                                        </div>
                            </Col>
                            <Col span="6">
                            <div className="relative mb-12">
                                <div className="absolute inset-0 bg-gradient-to-r from-gray-500 to-gray-500 shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0"></div>
                                <div className=" align-bottom bg-white md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl"><div className=" px-6 py-6"><div className="sm:flex sm:items-start"><div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-gray-500 to-gray-500"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-6 text-white"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" ></path></svg></div><div className="sm:mt-0 sm:ml-4 sm:text-left">
                                    <div as="h3" className="text-lg leading-6 font-medium text-gray-900">Tips</div>
                                    <ul className="list-disc mt-5">
                                        <li className="text-sm text-gray-500">Choose a topic that you are most knowledgeable in.</li>
                                        <li className="text-sm text-gray-500">Keep an eye on the timer.</li>
                                        <li className="text-sm text-gray-500">Increase the bid size to win more game tokens on each correct answer.</li>
                                    </ul>
                                </div></div></div></div></div>
                        </Col> 
                    </Grid> 

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        style={this.modalStyles}
                        contentLabel="Age Confirmation"
                    >
                        <h2 className="text-center font-semibold text-gray-500">Age & Bid Size Confirmation</h2>
                        <h3  className="text-center font-semibold text-green-500">Please provide your age in order to proceed with Quiz</h3>
                        <select onChange={(e) =>{this.selectAge(e)}} className="w-full p-3 rounded-sm border">
                            <option value="">Select Age</option>
                            {this.ageList.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                        </select><br/><br/>
                        <h3 className='text-center'>Bid Size <i>(How many tokens you would like to stake & win for each question)</i></h3>
                        <select className="w-full p-3 rounded-sm border" 
                                                value={this.state.bidSize} 
                                                onChange={this.updateBidSize}>
                                              <option value="1">1</option>
                                              <option value="5">5</option>
                                              <option value="10">10</option>
                                              <option value="15">15</option>
                                              <option value="20">20</option>
                                              <option value="25">25</option>
                        </select>
                        <div className="grid justify-items-center	">
                        <Button 
                            title="Proceed" 
                            Icon={InformationCircleIcon} 
                            onClick={this.submitAge} 
                        />
                        </div>
                    </Modal>

                    <Modal
                    isOpen={this.props.store.helpModalOpen}
                    style={this.helpModalStyles}
                    >
                        <h2 className="text-center font-semibold text-gray-500">Trial Mode</h2>
                        <h3  className="text-center text-green-500">On signup you are given free game tokens to play and get familiar with the game. You can play for free until you use up all your trial tokens. <br />Note: You cannot withdraw your trial winning tokens. </h3>
                        <br />
                        <h2 className="text-center font-semibold text-gray-500">Game Mode</h2>
                        <h3  className="text-center  text-green-500">You would need to buy tokens in order to play in game mode. You can win or lose tokens by playing the game. You can only withdraw your winning tokens.</h3>
                        <div className="grid justify-items-center">
                                <button onClick={(e) => { this.props.store.setHelpModal(false); }}
                                className={`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } >			
                                Ok</button>
                        </div>
			        </Modal>
                </Body>
            </>
        )
    }
}


export default withRouter(Tool)