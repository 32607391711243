import React, { Component } from 'react';
import  { Grid, Col } from '../Components/Body'
import { observer, inject,  } from 'mobx-react'
import moment from 'moment';


@inject('store')
@observer
class TransactionHistory extends Component {

	state = {
		history: [],
		success: false
	};
	queryString = new URLSearchParams(this.props.store.getQueryString());

    
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.getTransactionList();
		if (this.queryString.get('request') == 'true') {
			this.setState({success: true})
		}

		setTimeout(() => {
			this.setState({success: false})
		}, 6000);
	}

	getTransactionList = async () => {
		this.props.store.getTransactionHistory().then((data) => {
			this.setState({history: data});
		});
	}
	
	render() {
		return (
				<>
					<Grid>
						<Col span="10">
						{this.state.success ? <div className="text-green-600 bg-green-100 rounded-md p-2 text-center mt-4">
											Withdrawl request submitted succesfully. The balance will be updated in your BananaCrystal account in few mins.
						</div> : null}
	
							<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}>
								<div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="flex items-center">

									<div className=" mt-0 ml-0 text-left">
										<div as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Transaction History
										</div>
										<p className="text-sm text-gray-500">
										This provides the list of your token withdrawal history
										</p>
										</div>
									</div>
									<br/><br/>
									<div className="transaction-list">

										<table className="table-auto border-collapse border-solid text-center border-dark-green-500 border	 w-full	">
											<thead>
												<tr className="border border-solid leading-10">
													<th>S. No</th>
													<th>Withdrawl Date</th>
													<th>Crypto Tokens</th>
													<th>Game Tokens</th>
													<th>Banana Crystal Account</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody  className="border border-solid leading-10">
												{
													this.state.history.length > 0 ? 
													this.state.history.map((item, index) => 
														<tr>
															<td>{index+1}</td>
															<td>{moment(item.created).format('MM ddd, YYYY hh:mm a')}</td>
															<td>{item.cryptoTokens}</td>
															<td>{item.gameTokens}</td>
															<td>{item.bananaCrystalEmail}</td>
															<td>{item.status}</td>
														</tr>
														)
													: <tr className="p-2" >
													<td colSpan="5">No transactions found!</td>
												</tr>
												}
												
											</tbody>
										</table>	
									</div>
								</div>

               				 </div>
				
						</Col>
						
				</Grid>
			</>
		)
	}
}

  


export default TransactionHistory