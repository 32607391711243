import React, { Component } from 'react';
import  { Grid, Col } from '../Components/Body'
import Button from '../Components/Button'
import { observable, makeObservable } from 'mobx'
import { observer, inject,  } from 'mobx-react'
import config from './../config';


@inject('store')
@observer
class WithdrawToken extends Component {

	countdown
	@observable output = ""
	@observable outputs = []
    @observable outputError = ""

	@observable option = "Start Using"
	@observable options= []
	@observable currentOption = ""

    @observable loading = false
	@observable date = Date.now() + 1000

	// Basic Input
	@observable prompt = ""
	@observable promptError = ""
    @observable promptNumber = 0

	// Options of Inputs
	@observable promptOptions = []

	// Currently Selected Input Option
	@observable currentPromptOption = ""

	// Multiple Input Option
	@observable prompts = []

	@observable feedbacks = []
    
    @observable tool = {}
	queryString = new URLSearchParams(this.props.store.getQueryString());


	state = {
		isEditingDisabled: true,
		withdrawTokens: 0,
		availableWinningTokens: 0,
		bcEmail: null,
		bcToken: null,
		bcID: null,
		eqUSD: 0.00,
		eqOPAIG: 0.0,
		errorMessage: ''
	}
    
    constructor(props) {
        super(props)
        makeObservable(this)
    }

	componentDidMount() {

		const email = this.queryString.get('bc_email');
        const token = this.queryString.get('token');
		const uid = this.queryString.get('uid');
		localStorage.setItem('bc_token', token);
		localStorage.setItem('bc_uid', uid);

		const gameTokens = this.props.store.profile.winningGameTokens;
		this.setState({availableWinningTokens: gameTokens, bcEmail: email, bcToken: token, bcID: uid});
	}

	onClick = async () => {
		const params = {
			bananaCrystaluid: this.state.bcID,
			bananaCrystalEmail: this.state.bcEmail,
			cryptoTokens: this.state.eqOPAIG,
			gameTokens: this.state.withdrawTokens,
			authToken: this.state.bcToken
		};
		this.setState({ isEditingDisabled: true});

		this.props.store.submitWithdrawl(params).then((result) => {
			window.location.href = '/my-profile/transaction-history?request=true';
		});
	}

	getConversionRate = async () => {
		if (this.state.availableWinningTokens < this.state.withdrawTokens) {
			this.setState({errorMessage: 'The withdraw tokens can not be greater than available tokens.'});
			return;
		}

		this.props.store.getConversionRate().then((data) => {
			const USD = this.state.withdrawTokens > 0 ? this.state.withdrawTokens * config?.cryptoTokenValue * data.token_out_amount: 0;
			const OPAID = this.state.withdrawTokens > 0 ? this.state.withdrawTokens * config?.cryptoTokenValue: 0;
			this.setState({eqUSD: USD, eqOPAIG: OPAID, errorMessage: '', isEditingDisabled: false});
		});
	}

	onChange = e => {
		this.props.store.feedback = e.target.value
	}

	redirectToBananaCrystal = () => {
		window.location.href = `${config.bananaCrystalSSO}`;

	}
	
	render() {
		return (
				<>
					<Grid>
						<Col span="10">
	
							<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}>
								<div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="flex items-center">

									<div className=" mt-0 ml-0 text-left">
										<div as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Withdraw Tokens
										</div>
										
										<p className="text-sm text-green-500">
										Note: After successfully completing transaction your OPAIG token would be available in your BananaCrystal wallet balances.
										</p>
										</div>
									</div>
									<br/><br/>
									<form>
									<Grid>
										<Col span="12">
											<label>Available Game Tokens to withdraw: {this.state.availableWinningTokens}</label>
										</Col>
										<Col span="12">
											{this.state.bcEmail ? 
											<span>BananaCrystal account connected : <i className='text-green-500'>{this.state.bcEmail}</i></span>
											:
											<a onClick={(e) => this.redirectToBananaCrystal()} id="connect-bc-btn"
											className='select-none py-3 px-12 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition'>Connect your BananaCrystal Account</a>
											}
										</Col>	
										<Col span="12">
											<label>How many tokens to withdraw:</label><br/>
											<input type="number" className="px-4 py-3 ml-0 rounded-full withdraw-token-field border" onChange={(e)=> { this.setState({withdrawTokens: e.target.value}) }} defaultValue="0"/>
											 &nbsp;<a onClick={(e) => this.getConversionRate()} id="convert-btn" className="select-none py-3 px-12 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition">Convert</a>
										</Col>
										<Col span="12">
										{
											this.state.errorMessage ? <span className="text-red-500">{this.state.errorMessage}</span>: null
										}
										</Col>
										<Col span="12">
											<label>1 OPAIG (Blockchain crypto token): {(1/config?.cryptoTokenValue).toFixed(4)} Game Tokens</label>
										</Col>
										<Col span="12">
											<label>Equivalent OPAIG (Blockchain crypto token): {this.state.eqOPAIG}</label>
										</Col>
										<Col span="12">
											<label>Equivalent USD: {this.state.eqUSD}</label>
										</Col>
										
										<Col span="12">
											<button disabled={this.state.isEditingDisabled} onClick={(e) => this.onClick()} className="select-none py-3 px-12 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition">Withdraw Now</button>
										</Col>
									</Grid>
									</form>
						
								</div>

               				 </div>
				
						</Col>
						
				</Grid>
			</>
		)
	}
}

  


export default WithdrawToken