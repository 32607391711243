import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { computed,  } from 'mobx'
import MainBody from './Components/Body'
import {Helmet} from "react-helmet";
import Modal from 'react-modal';
import { observer, inject } from 'mobx-react'
import config from './config';

@inject('store')
@observer
class Body extends Component {
	state = {
        modalIsOpen: false,
		orderModalIsOpen: false
    };

	componentDidMount() { 
		this.props.store.getCategories();
		this.setState({modalIsOpen: this.props.store.profile.isFirstLogin});
		const isOrdered = new URLSearchParams(window.location.search).get("order")
		if (isOrdered === 'true') {
			this.setState({orderModalIsOpen: true})
		}
	}

	@computed get everydayLife() {
		return this.props.store.categories.filter(cat => cat.parent === 'Everyday Life')
	}

	@computed get societyCulture() {
		return this.props.store.categories.filter(cat => cat.parent === 'Society & Culture')
	}

	@computed get scienceTechnology() {
		return this.props.store.categories.filter(cat => cat.parent === 'Science & Technology')
	}

	@computed get mathematics() {
		return this.props.store.categories.filter(cat => cat.parent === 'Mathematics')
	}

	modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '450px',
            height: '330px'
        }
    }

	modalOrderedStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: '260px'
        }
    }

	helpModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '600px',
			width: '100%',
            height: 'auto',
			minHeight: '335px'
        }
    }

	hideWelcomePopup = () => {
		this.setState({ modalIsOpen:false});
	}

	hideOrderModal = () => {
		this.setState({ orderModalIsOpen:false});
	}

	buyMore = () => {
		window.location.href = `${config.landingPageUrl}/shop/`;
	}  

	render() {
	return (

		<>
			<Helmet>
				<title>{`Oval Pixel - Play, Learn and Earn Rewards`}</title>
			</Helmet>
			<MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">

		

			{this.everydayLife.length ? <>
				<Title title="Everyday Life" />
				<Grid>
					{this.everydayLife.map((tool, index) => 
						<CategoryCard 
							key={index}
							group={tool.parent}
							title={tool.title} 
							to="/ai/example"
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.societyCulture.length ? <>
				<Title title="Society & Culture" />
				<Grid>
					{this.societyCulture.map((tool, index) => 
						<CategoryCard 
							key={index}
							group={tool.parent}
							title={tool.title} 
							to="/ai/example"
						/>)} 
				</Grid>
				<Divider />
				</> : null}

			{this.scienceTechnology.length ? <>
				<Title title="Science & Technology" />
				<Grid>
					{this.scienceTechnology.map((tool, index) => 
						<CategoryCard 
							key={index}
							group={tool.parent}
							title={tool.title} 
							to="/ai/example"
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.mathematics.length ? <>
				<Title title="Mathematics" />
				<Grid>
					{this.mathematics.map((tool, index) => 
						<CategoryCard 
							key={index}
							group={tool.parent}
							title={tool.title} 
							to="/ai/example"
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			<Modal
                isOpen={this.state.modalIsOpen}
                style={this.modalStyles}
                contentLabel="Welcome! Free Game Tokens"
                >
                <h2 className="text-center font-semibold text-gray-500">Welcome!</h2>
                <h3  className="text-center font-semibold text-green-500">We have gifted you {config.trialGameTokens} Free trial tokens to play your game 🥳</h3><br/>
				<p className="text-center font-semibold text-red-500">Note: You can only earn rewards with game tokens</p><br/>
				<p className="text-center font-semibold text-gray-500">To buy game tokens to win and earn rewards  <br/><a href='javascript:void(0)' onClick={this.buyMore} className='text-green-500'>Click here</a></p>
            	<div className="grid justify-items-center">
                        <button 
							onClick={this.hideWelcomePopup}
                       			
			className={
				`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
				>			
				Thanks</button>
                </div>
            </Modal>

			<Modal
                isOpen={this.state.orderModalIsOpen}
                style={this.modalOrderedStyles}
                >
                <h2 className="text-center font-semibold text-gray-500">Thank you for your order!</h2>
                <h3  className="text-center font-semibold text-green-500">You're game tokens are being processed. Please be patient as it may take a few minutes to credit your game tokens.</h3>
			
				<h3 className="text-center font-semibold text-green-500">You can also refresh your page in few mins to see your game tokens.</h3>
            	<div className="grid justify-items-center">
                        <button 
							onClick={this.hideOrderModal}
                       			
			className={
				`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } 
				>			
				Thanks</button>
                </div>
            </Modal>

			<Modal
			isOpen={this.props.store.helpModalOpen}
			style={this.helpModalStyles}
			>
				<h2 className="text-center font-semibold text-gray-500">Trial Mode</h2>
				<h3  className="text-center text-green-500">On signup you are given free game tokens to play and get familiar with the game. You can play for free until you use up all your trial tokens. <br />Note: You cannot withdraw your trial winning tokens. </h3>
				<br />
				<h2 className="text-center font-semibold text-gray-500">Game Mode</h2>
				<h3  className="text-center  text-green-500">You would need to buy tokens in order to play in game mode. You can win or loose tokens by playing the game. You can only withdraw your winning tokens.</h3>
				<div className="grid justify-items-center">
						<button onClick={(e) => { this.props.store.setHelpModal(false); }}
						className={`select-none py-3 px-6 border-t-2 border-green-400 bg-green-500 hover:bg-green-600 hover:via-blue-700 hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition` } >			
						Ok</button>
				</div>
			</Modal>
</MainBody>
</>)
}
  }

export const Divider = () => <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12"> <div></div>
<div></div></div>

export const Title = ({ title }) => <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
{title}
</h2>

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative ">
	
	<div className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}>
  <div className="p-4">
	<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
	<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
	<p className="mt-1 pr-1 text-sm ">{desc} </p>
  </div>
</div>
</Link>

export const CategoryCard = ({ title, to, group, fromColor }) => <Link to={to+"?category="+title.replace('&', ';')+"&parent="+group || "/"} className="flex relative ">
	
	<div className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}>
  <div className="p-4">
	<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
	<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
	<p className="mt-1 pr-1 text-sm ">Click to play quiz</p>
  </div>
</div>
</Link>



export default Body