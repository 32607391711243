import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import config from './../config';

@inject('store')
@observer
class Help extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<>

<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8 `}>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

				 <h2 className="text-4xl">Help</h2>
				 <h2 className="text-gray-500 mb-4">Learn how to use OvalPixel and get answers to your questions.</h2>
				 
				 <div class="accordion flex flex-col">

					<div class="w-2/3">
						<input type="checkbox" name="panel" id="panel-1" class="hidden" />
						<label for="panel-1" class="relative block bg-black text-white p-4 shadow border-b border-grey">How do I play the game?</label>
						<div class="accordion__content overflow-hidden bg-grey-lighter">
							<p class="accordion__body p-4" id="panel1">
								<ul className='list-disc'>
									<li>Select any Category and Topic of your choice.</li>	
									<li>Select your age & bid size.</li>
									<li>Answer multiple choice question.</li>	
									<li>Select your answer and click next before the time runs out.</li>	
									<li>Win or lose game tokens on each correct/wrong answer.</li>
									<li>Change your bid size to earn more tokens for each correct answer.</li>
								</ul>
							</p>
						</div>
					</div>

					<div class="w-2/3">
						<input type="checkbox" name="panel" id="panel-2" class="hidden" />
						<label for="panel-2" class="relative block bg-black text-white p-4 shadow border-b border-grey">What is the difference between Game vs. Trial mode?</label>
						<div class="accordion__content overflow-hidden bg-grey-lighter">
							<h2 class="accordion__header pt-4 pl-4 font-semibold">Trial Mode</h2>
							<p class="accordion__body p-4">On signup you are given free game tokens to play and get familiar with the game. You can play for free until you use up all your trial tokens.
			Note: You cannot withdraw your trial winning tokens. </p>
							<h2 class="accordion__header pt-4 pl-4 font-semibold">Game Mode</h2>

							<p class="accordion__body p-4">You would need to buy tokens in order to play in game mode. You can win or lose tokens by playing the game. You can only withdraw your winning tokens.</p>
						</div>
					</div>

					<div class="w-2/3">
						<input type="checkbox" name="panel" id="panel-3" class="hidden" />
						<label for="panel-3" class="relative block bg-black text-white p-4 shadow border-b border-grey">Why don't I see my purchased game tokens in my account?</label>
						<div class="accordion__content overflow-hidden bg-grey-lighter">
							<p class="accordion__body p-4">It takes few mins for tokens to reflect into your account. However you can always create a support ticket or reach us directly through email at <a href="mailto:support@ovalpixel.com" className="text-green-500">support@ovalpixel.com</a>.</p>
						</div>
					</div>

					<div class="w-2/3">
						<input type="checkbox" name="panel" id="panel-4" class="hidden" />
						<label for="panel-4" class="relative block bg-black text-white p-4 shadow border-b border-grey">Where I will receive OPAIG crypto tokens after withdrawl?</label>
						<div class="accordion__content overflow-hidden bg-grey-lighter">
							<p class="accordion__body p-4">After successfull transaction the OPAIG token balance would be in your BananaCrystal account wallet. However you can always create a support ticket or reach us directly through email at <a href="mailto:support@ovalpixel.com" className="text-green-500">support@ovalpixel.com</a>.</p>
						</div>
					</div>
				</div>
			</div>

        </div>
			
			</>
		)
	}
}

export default Help